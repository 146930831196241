@import "src/styles/mixins.scss";
@import "src/configs/styles/variables.scss";

.medium-modal {
  .admin-content.form-content {
    width: 408px;
    margin: auto;
  }
}

.common-header {
  @include flex;
  @include justify-content(space-between);
  margin-bottom: 20px;
  .ant-btn {
    margin-left: 16px;
  }
}

.admin-content {
  background: $white-color;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.common-form {
  @include flex;
  padding: 24px 24px 12px 24px;
  @include flex-direction(column);
  
  .form-footer {
    margin-top: 20px;
    @include flex;
    @include justify-content(flex-end);

    button {
      border-radius: 2px;
      margin-left: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .line-break {
    height: 1px;
    background: $background-color;
    margin: 0 -24px;
  }
}

.route-status {
  width: 8px;
  height: 8px;
  margin-right: 3px;
}

.admin-route-detail {
  .wrap-mapbox {
    position: relative;
    .wrap-driver-info {
      color: #000000;
      position: absolute;
      top: 5%;
      z-index: 100;
      background: #fff;
      width: 90%;
      left: 5%;
      padding: 24px;
      border-radius: 16px;
      .driver-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .route-description {
    padding: 0px 28px 0px 28px;
    .route-rate {
      margin-bottom: 20px;
      .route-rate-value {
        color: $primary-color;
        font-weight: bold;
        text-align: center;
        border-radius: 2px;
        padding-right: 22px;
      }
      svg {
        position: absolute;
        margin-left: 6px;
        margin-top: 4px;
      }
      .route-rate-information {
        margin-left: auto;
        color: $third-color;
        text-align: right;
      }
    }
    .route-pickup {
      .route-pickup-title {
        font-weight: 600;
        color: $secondary-text;
        margin-bottom: 4px;
      }
      .route-pickup-value {
        font-size: 14px;
        color: $primary-text;
        margin-bottom: 16px;
      }
    }
    .route-direction {
      margin-bottom: -23px;
      .ant-timeline-item {
        padding-bottom: 8px;
      }
      .ant-timeline-item-tail {
        border-left: 1px dotted $secondary-color;
        left: 4.5px;
      }
      .ant-timeline-item-content {
        color: $primary-text;
      }
      .route-direction-end {
        .ant-timeline-item-head {
          background: $secondary-color;
        }
      }
    }
    .route-stops-vehicle-type {
      margin-right: -10px;
      margin-bottom: 20px;
      .route-stops {
        color: $secondary-color;
      }
      .route-vehicle-Type {
        color: $primary-text;
        text-align: right;
        border-radius: 2px;
        margin-left: auto;
        .route-vehicle-Type-value {
          margin-left: 3px;
          color: $secondary-color;
        }
      }
    }
    .route-description-status {
      margin-bottom: 24px;
      .route-description-status-tag {
        width: 94px;
        color: $tertiary-color;
        text-align: center;
        border-radius: 2px;
      }
      svg {
        margin-left: auto;
      }
    }
    .route-description-hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1.5px solid #E5E5E5;
      margin: 0px -28px 24px -28px;
    }
    .route-client-title {
      font-size: 14px;
      margin-bottom: 8px;
      svg {
        margin-left: auto;
        margin-right: -10px;
      }
    }
    .route-service {
      .route-service-title {
        font-weight: 600;
        color: $secondary-text;
        margin-bottom: 4px;
      }
      .route-service-value {
        font-size: 14px;
        color: $primary-text;
        margin-bottom: 16px;
      }
    }
  }
}

.route-location {
  width: 80%;
  margin: auto;
  margin-top: 24px;
  .route-location-timeline {
    .ant-timeline-item:last-child {
      .ant-timeline-item-tail {
        visibility: hidden;
      }
    }

    .ant-timeline-item-tail {
      margin-top: 24px;
      border-left: 2px solid #0078D4;
      left: 4px;
    }
    .ant-timeline-item-head {
      background: $background-color;
      width: 0px;
      height: 0px;
      margin-top: 14px;
      margin-left: -9px;
    }
  }
  .route-location-content {
    margin-left: 8px;
    background: $white-color;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 24%);
    border-radius: 4px;
    padding: 24px;
    .ant-image-img {
      object-fit: cover;
    }
    .route-location-title-name {
      font-weight: 600;
      color: $primary-text;
      margin-bottom: 24px;
    }
    .route-location-title-status {
      padding-right: 0px;
      text-align: center;
      border-radius: 2px;
    }
    .route-location-title-status-number {
      min-width: fit-content;
      color: $white-color;
      text-align: center;
      border-radius: 2px;
      font-weight: 400;
    }
    .route-location-title {
      .route-location-title-status {
        margin-left: auto;
        margin-bottom: 24px;
        padding-left: 0px;
      }
      svg {
        margin-top: 7px;
        margin-left: 7px;
      }
    }
    .route-location-address {
      margin-bottom: 12px;
    }
    .route-location-sender {
      .route-location-sender-title {
        font-weight: 600;
        color: $secondary-text;
        margin-bottom: 4px;
      }
      .route-location-sender-value {
        font-size: 14px;
        color: $primary-text;
        margin-bottom: 0px;
      }
      .route-location-sender-value-bottom {
        font-size: 14px;
        color: $primary-text;
        margin-bottom: 24px;
      }
    }
    .route-location-hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1.5px solid #E5E5E5;
      margin: 0px -24px 24px -24px;
    }
    .route-location-package-name {
      font-weight: 600;
      color: $primary-text;
    }
    .route-location-package {
      margin-top: 8px;
      margin-bottom: 24px;
    }
    .route-location-proof {
      height: 208px;
      .route-location-proof-left {
        border-right: 1.5px solid #E5E5E5;
        padding-right: 24px;
      }
      .route-location-proof-right {
        padding-left: 24px;
      }
      .route-location-proof-empty {
        position: absolute;
        color: $secondary-text;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
      }
      .route-location-signature {
        width: 330px;
        height: 160px;
      }
      .route-location-proof-img {
        width: 96px;
        height: 96px;
        margin-right: 15px;
      }
      .route-location-proof-img:last-child {
        margin-right: 0px;
      }
    }
  }
}

.ant-table-wrapper {
  .ant-btn-text {
    padding: 8px;
  }
}
