@import "src/configs/styles/variables.scss";
.polygon-mapbox-component {
  .wrap-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .title, .redraw-action {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
  }
  .redraw-action {
    width: 140px;
    display: flex;
    justify-content: space-between;
    text-align: right;
    cursor: pointer;
    color: $primary-color;
  }
  .hint {
    font: 12px;
    font-style: italic;
    color: $tertiary-color;
  }
  .place-polygon-seach-wrapper {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 300px;
    // max width prevents it overlaying the polygon select buttons on mobile
    max-width: 80%;
    z-index: 10;
  }
}
