@import "/src/configs/styles/variables.scss";

.route-driver-chart {
  width: 100%;
  height: 48px;
  .wrap-group-stop {
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;
    &.isDragging {
      &.optimize {
        cursor: no-drop;
      }
    }
    .driver-stop {
      min-width: 24px;
      font-weight: bold;
      text-align: center;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      height: 24px;
      border-width: 1px;
      border-style: solid;
      background-color: $white-color;
      cursor: pointer;
      &.pickup {
        border-radius: 12px;
      }
    }
  }
  .group-line-chart {
    position: absolute;
    display: flex;
    align-items: center;
    &.isDragging {
      border: dashed 1px #cecece;
      &.isFocusDnd {
        border: dashed 1px !important;
      }
      &.optimize {
        cursor: no-drop !important;
        border: none;
      }
    }
    .start-driver {
      display: flex;
      z-index: 1;
    }
    .driver-stop {
      font-size: 12px;
      display: flex;
      align-items: center;
      position: absolute;
      height: 24px;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      background-color: $white-color;
      cursor: pointer;
    }
    .road-line {
      position: absolute;
      height: 2px;
    }
  }
}
