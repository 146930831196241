.marker {
  cursor: pointer;

  &__info {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 2px;
  }

  &--driver {
    cursor: pointer;
  }
}

.active-pin {
  z-index: 1;
}

.mapboxgl-popup {
  user-select: text !important;
}
