@import "src/styles/mixins.scss";
.client-sider-content-component {
  z-index: 999;
  position: relative;
  padding: 1em;

  .ant-menu-inline-collapsed {
    .ant-menu-item svg {
      width: 20px;
      margin-right: 0;
    }
  }
  .button-collapse {
    cursor: pointer;
    position: absolute;
    right: -14px;
    bottom: 125px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ffffff;
    span {
      z-index: 9;
      font-size: 14px;
      // padding: 8px;
      cursor: pointer;
    }
  }

  .anticon svg path {
    fill: #9197b3;
  }

  .ant-layout-sider-children {
    padding: 30px 0 !important;
    // background-color: #2491bc;
    max-height: calc(100vh - 64px);
    overflow: hidden;
    overflow-y: auto;
  }
  .ant-menu-item-icon {
    padding-top: 3px;
  }

  .ant-menu-item-only-child {
    padding-left: 74px !important;
  }

  ul {
    // background-color: #2491bc;
    border: none;

    li {
      span {
        color: #9197b3;
      }

      .count-noti {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #f53d1a;
        font-size: 12px;
        line-height: 16px;
      }

      &.hasCount {
        .ant-menu-title-content {
          @include flex;
          @include justify-content(space-between);
          @include align-items(center);

          .count {
            display: inline-block;
            min-width: 20px;
            height: 20px;
            background: #f53d1a;
            padding: 2px 4px 2px 2px;
            font-size: 12px;
            line-height: 16px;
            border-radius: 4px;
            margin-top: 3px;
          }
        }
      }
    }
  }
  .ant-menu-item {
    border-radius: 8px;
    svg {
      width: 30px;
      margin-right: 10px;
    }
    &.ant-menu-item-active {
      span.ant-menu-title-content {
        color: #9197b3 !important;
      }
    }
  }

  .ant-menu-title-content a:hover {
    font-weight: bold;
  }

  .ant-menu-item.ant-menu-item-selected {
    .anticon svg path {
      fill: #43c1c2;
    }
    &.ant-menu-item-active {
      span.ant-menu-title-content {
        color: #fff !important;
      }
    }
  }
  .ant-menu-item.ant-menu-item-selected {
    background: #1278f1 !important;
    span.ant-menu-title-content {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff !important;
    }
  }

  .ant-menu.ant-menu-sub {
    span.ant-menu-title-content {
      font-size: 14px;
      line-height: 24px;
      color: #9197b3 !important;
    }
    .anticon svg path {
      fill: #43c1c2;
    }
  }

  .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
    background: #1278f1 !important;
    border-radius: 8px;

    span.ant-menu-title-content {
      // font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
    }

    .anticon svg path {
      fill: #43c1c2;
    }
  }

  .ant-menu-item::after {
    border: none;
  }

  .ant-menu-item.ant-menu-item-active {
    span.ant-menu-title-content {
      // font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #43c1c2;
    }
  }

  .ant-menu-item-selected {
    background-color: none !important;
  }

  .ant-menu-sub.ant-menu-inline {
    padding: 0;
    background: #fff;
    border: 0;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 30px;
  }

  .ant-menu-item.ant-menu-item-selected.ant-menu-active {
    &.ant-menu-item-active {
      span.ant-menu-title-content {
        color: #fff !important;
      }
    }
  }
  .ant-menu-submenu-inline {
    .ant-menu-submenu-title {
      svg {
        width: 30px;
        margin-right: 10px;
      }
    }
    i.ant-menu-submenu-arrow {
      visibility: hidden;
    }
  }
  .iconClass {
    width: 20px;
    height: 20px;
    background-size: contain !important;
  }
  .operation-icon {
    background: url("./icons/operations.svg") center no-repeat;
  }
  .clock-icon {
    background: url("./icons/clock.svg") center no-repeat;
  }
  .mapIcon {
    background: url("./icons/map.svg") center no-repeat;
    width: 18px;
  }
  .clientIcon {
    background: url("./icons/client.svg") center no-repeat;
    width: 18px;
  }
  .territoryIcon {
    background: url("./icons/territory.svg") center no-repeat;
  }
  .driverClass {
    background: url("./icons/driver.svg") center no-repeat;
    width: 18px;
  }
  .adminClass {
    background: url("./icons/admin.svg") center no-repeat;
    width: 18px;
  }
  .countriesIcon {
    background: url("./icons/countries.svg") center no-repeat;
  }
  .servicesIcon {
    background: url("./icons/services.svg") center no-repeat;
  }
  .vehiclesIcon {
    background: url("./icons/vehicles.svg") center no-repeat;
  }
  .packagesIcon {
    background: url("./icons/packages.svg") center no-repeat;
  }
  .failedIcon {
    background: url("./icons/failedregister.svg") center no-repeat;
  }
  .couponIcon {
    background: url("./icons/coupon.svg") center no-repeat;
  }

  .plan-new-route-btn {
    background: #00e2dc;
    color: #004dbc;
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    display: block;
    max-width: 180px;
    margin: auto;
    font-size: 0.9rem;
  }

  .plan-new-route-btn:hover {
    transform: scale(1.03);
    box-shadow: 3px 2px 4px 2px #00000020;
  }
}

@media only screen and (max-width: 500px) {
  .plan-new-route-btn {
    font-size: 12px;
  }
}
