@import "src/configs/styles/variables.scss";

.admin-wrap-step-1-form {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  // align-items: center;
  .flexio-vehicle-types-check-list {
    .ant-checkbox-group {
      display: grid;
      grid-template-columns: repeat(3, 1fr)
    }
  }
  .group-flexio-vehicle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }
  .switch-hook {
    padding-top: 33px;
    margin-bottom: 0;
  }
  .group-form {
    padding: 12px;
    padding: 24px 24px 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }

  }
  .link {
    color: $secondary-color;
  }
  .agree-checkbox {
    margin-top: 30px;
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
