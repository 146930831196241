.status {
  margin-top: 8px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  div {
    margin-right: 10px;
  }
}
.cards-container {
  display: flex;
  flex-wrap: wrap;

  .card-container {
    width: 50%;
    height: 200px;
    overflow: hidden;
    margin-bottom: 10px;

    @media only screen and (max-width: 700px) {
      width: 100%;
      position: relative;
      height: 10rem;
    }
    .card {
      display: flex;
      align-items: center;
      min-width: 80%;
      width: 100%;
      height: 100%;
    }
  }
  .stats-card {
    font-size: 12px;
    height: 100%;
    border: solid 1px black;
    @media only screen and (max-width: 700px) {
      font-size: 8px;
      height: auto;
      
    }
  }
}
