.PaymentForm {
  width: 500px;
  padding: 20px 70px;
  margin-top: 30px;
}
.KeepItBlue {
  background-color: #1f3998;
  color: white;
}
.CardName {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;

  &::placeholder {
    color: #ccc;
  }
  &:focus {
    border: 1px solid #a00;
  }
}

.CardWrapper {
  .StripeElement {
    margin-top: 10px;
    border: 1px solid #ced4da;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    font-size: 40px;
  }

  .StripeElement--focus {
    border: none;
    outline: none;
    box-shadow: 0 0 0 3px rgba(5, 112, 222, 0.2); // Optional: add a sh
  }

  .StripeElement--invalid {
    border-color: #df1b41;
  }
}
