.autocomplete-dropdown-container {
  background-color: #fff;
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 35px;

  .suggestion-item, .suggestion-item--active {
    padding: 5px;
    border: 2px solid rgba(211, 47, 47, 0.2);
    border-top: none;
    border-bottom: none;

    &:last-child {
      border-bottom: 2px solid rgba(211, 47, 47, 0.2) !important;
      border-radius: 0 0 5px 5px;
    }

    &:first-child {
      border-top: 2px solid rgba(211, 47, 47, 0.2) !important;
    }
  }
}

.autocomplete-dropdown-container:empty {
  display: none;
}
