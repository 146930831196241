.home-wrapper {
  .greetings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-template-rows: min-content;
    align-self: center;
    column-gap: 20px;
    row-gap: 10px;
    margin: 1em 0 0 0;
  }

  .user-greetings {
    font-size: 18px;
    margin: 0;
  }
  .sub-greetings {
    font-weight: 800;
    font-size: 20px;
    margin: 0;
  }

  .ant-card {
    padding: 2em 0 !important;
    border-radius: 30px !important;

    .card-icon {
      margin: 0 0 1em 0;
    }

    .card-title {
      color: #004dbc;
      font-weight: 700;
      font-size: 20px;
      margin: 1em 0 0.5em 0 !important;
    }

    .card-description {
      color: #313131;
      font-size: 12px;
      margin-bottom: 1em;
    }

    .card-cta {
      background-color: #1278f1;
      border: 0;
      margin: 2em auto 0 auto;
      display: block;
      border-radius: 8px;
      max-width: 200px;
      width: 100%;
      color: #fff;
      padding: 0.6em 1em;
      font-weight: bold;
    }

    &:nth-child(6) {
      background-color: #004dbc;

      .card-title {
        color: #fff;
      }

      .card-description {
        color: #fff;
      }

      .card-cta {
        background-color: #00e2dc;
        color: #004dbc;
      }
    }
  }
}

li {
  list-style-type: none;
}

@media only screen and (max-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(2, minmax(300px, 1fr)) !important;
  }
}
@media only screen and (max-width: 940px) {
  .card-container {
    grid-template-columns: repeat(1, minmax(300px, 1fr)) !important;
  }
}
@media only screen and (min-width: 2300px) {
  .card-icon svg {
    width: 60px;
    height: 60px;
  }

  .card-title {
    font-size: 26px !important;
  }

  .card-description {
    font-size: 18px !important;
  }

  .card-cta {
    padding: 1em 1.3em;
    font-size: 18px;
  }
}
