.baseCard {
  background-color: #FFFFFF;
  box-shadow: 0 0 2px #E0E0E0;
  border-radius: 4px;
  padding: 16px;
}

.instruction {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 80px;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 24px;

  & > div:first-child {
    grid-area: 1/1;
  }

  & > div:last-child {
    grid-area: 2/1/span 1/span 2;
  }

  .instructionContent {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    label {
      font-weight: bold;
      margin-bottom: 4px;
    }

    .area {
      background-color: #F4F4F4;
      border-radius: 2px;
      padding: 10px;
      min-height: 100px;
    }
  }
}

.container {
  @extend .baseCard;
  flex: 1;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    margin-left: -16px;
    gap: 16px;
    font-weight: bold;

    &::before {
      content: '';
      width: 4px;
      height: 24px;
      background-color: #1F3998;
    }
  }

  .sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 80px;
    row-gap: 16px;

    .topSection {
      grid-area: 1/1/span 1/span 2;
    }

    .leftSection, .rightSection {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .leftSection {
      grid-area: 2/1;
    }

    .rightSection {
      grid-area: 2/2;
    }
  }

  .divider {
    width: calc(100% + 32px);
    margin-left: -16px;
    height: 1px;
    background-color: #E0E0E0;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .btnUpdate {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: min-content;
    margin-left: auto;
  }
}
