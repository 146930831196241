.info-route {
  .content-header {
    .title {
      font-size: 12px;
      line-height: 24px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      color: #212121;
      img {
        padding-right: 5px;
      }
    }

    table {
      width: 100%;

      th {
        color: #888888;
        font-weight: 600;
      }

      th,
      td {
        padding-right: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;
        font-size: 12px;
      }
    }
    .address {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: keep-all;
      width: 280px;
    }
  }

  .content-main {
    margin-top: 10px;
    max-height: 50vh;
    overflow: scroll;
    position: relative;
    background: #fff;
    table {
      width: 100%;

      thead {
        background: #000;
        color: #fff;
        position: sticky;
        top: 0;
        z-index: 1;
        th {
          padding: 10px 5px;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: keep-all;
        }
      }

      tbody {
        tr {
          td {
            font-size: 12px;
            padding: 5px;
            border-bottom: 1px solid #f0f0f0;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: keep-all;
            height: 48px;
          }
        }

        tr:hover {
          background-color: #f5f5f5;
        }
      }
    }
    .address {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: keep-all;
      width: 200px;
    }
    .stop-status {
      border-radius: 2px;
      padding: 4px 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      &.completed {
        background: #D6F6E3;
        color: #00C853;
      }
      &.pending {
        background: #ECECEC;
        color: #888888;
      }
      &.assigned {
        background: #DEECF9;
        color: #0078D4;
      }
      &.arrived {
        background: #f9efdb;
        color: #ffab00;
      }
    }
  }
}
