@import "src/configs/styles/variables.scss";

.card-component {
  background-color: #fff;
  .wrap-card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .right-title {
      padding-right: 24px;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
    }
  }
  .card-title {
    padding-left: 20px;
    border-left: solid 4px $primary-color;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}