$primary-color: #1F3998;
$secondary-color: #43C1C2;
$tertiary-color: #FF8400;
$third-color: #FF3D17;
$active-color: #00C853;
$border-color: #E0E0E0;
$white-color: #FFFFFF;
$primary-text: #212121;
$secondary-text: #888888;
$background-color: #F4F4F4;
$red-color: #FF3D17;
$orange-color: #FF8400;
$text-color: #212121;
$padding-default: 24px;
$one-hour-px: 200px;

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}

.text-primary {
  color: $primary-text;
}

.text-tertiary {
  color: $tertiary-color !important;
}

.text-secondary {
  color: $secondary-text;
}

.text-red {
  color: $red-color;
}

.font-24 {
  font-size: 24px;
}
.font-16 {
  font-size: 24px;
}
