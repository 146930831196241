@import "/src/configs/styles/variables.scss";

.slot-component {
  font-weight: bold;
  text-align: center;
  color: $primary-color;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px $primary-color;
}
