@import "../configs/styles/variables.scss";

.text-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}
//Background
.bg-white {
  background-color: #fff !important;
}

.secondary-text {
  color: $secondary-text !important;
}

img {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.fw-bold-500 {
  font-weight: 500;
}

.ant-popover {
  &.no-padding {
    .ant-popover-inner-content {
      padding: 0 !important;
    } 
  }
}

.ant-btn {
  &.no-padding {
    padding: 0 !important;
  }
}

.content-table {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

.ant-layout-content {
  min-height: calc(100vh - 64px) !important;
}

.ant-layout {
  &.ant-layout-has-sider {
     > div { width: 100%; }
  }
}

.ant-modal {
  .form-content {
    label {
      font-weight: 600;
      font-size: 12px;
    }
    .item-label {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;

      .right {
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #888888;
      }
    }
  }
}

.no-padding-modal {
  .ant-modal-body {
    padding: 0;
  }
}
.ant-modal-header {
  padding: 16px 0 !important;
  border-bottom: none !important;
}
.ant-modal-footer {
  button {

    span {
      font-weight: 600;
      line-height: 24px;
      font-size: 14px;
    }
  }
}

.ant-modal-content {
  border-radius: 4px;
}
.ant-modal-title {
  border-left: 4px solid #4285F4;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
}

.ant-modal-footer {
  button {

    span {
      font-weight: 600;
      line-height: 24px;
      font-size: 14px;
    }
  }
}

.ant-modal-content {
  border-radius: 4px;
}

.ant-modal-title {
  border-left: 4px solid #4285F4;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
}

.ant-pagination {
  background-color: rgba(244, 244, 244, 0.6);
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 !important;
  height: 64px;
  position: relative;

  .ant-select-selector {
    height: 32px;
  }
  li {
    &.ant-pagination-total-text {
      font-size: 12px;
      left: 16px;
      position: absolute;
      top: 24px;
    }

    &.ant-pagination-options {
      right: 16px;
      position: absolute;
      top: 16px;

      .ant-select-selection-item {
        font-size: 12px;
        text-align: center;
      }
    }

    &.ant-pagination-item {
      &.ant-pagination-item-active {
        a {
          color: white;
        }
      }
    }

    &.ant-pagination-prev, &.ant-pagination-next {
      button {
        background: none;
        border: none;
      }
    }
  }
}

.app-modal-spin {
  background: none;
}

.form-content, .detail-content {
  padding-top: 24px;

  h2.title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-left: 20px;
    color: #212121;
    border-left: #1F3998 solid 4px;
  }
}

.react-tel-input {
  width: 100%;
  height: 40px;
  input.form-control {
    width: 100% !important;
    border-radius: 0px !important;
    height: 40px;
    background: #f4f4f4;
  }
}

// checkbox

.ant-checkbox-wrapper {
  &.secondary {
    .ant-checkbox-checked::after {
      border-color: $secondary-color !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: $primary-color;
  border-color: $primary-color;
}

// button

.ant-btn {
  &.ant-btn-secondary-with-icon {
    background-color: #ffffff;
    color: $primary-color;
    box-shadow: none;
    border: solid 1px $primary-color;
  }
  &.ant-btn-tertiary {
    background-color: $tertiary-color;
    color: #ffffff;
    box-shadow: none;
    border: none;
    &:hover {
      border: none;
      color: #ffffff;
      background-color: $tertiary-color;
      opacity: 0.8;
    }
    &:focus {
      border: none;
      color: #ffffff;
      background-color: $tertiary-color;
      opacity: 1;
    }

  }
  &.ant-btn-active {
    background-color: $active-color;
    color: #ffffff;
    box-shadow: none;
    border: none;
    &:hover {
      background-color: $active-color;
      color: #ffffff;
      opacity: 0.8;
    }
    &:focus {
      background-color: $active-color;
      color: #ffffff;
    }

  }

  &.no-border {
    border: none;
    background: none;
    box-shadow: none;
    &:disabled {
      border: none;
      background: none;
      box-shadow: none;
    }

  }
  &.svg-icon {
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
      &.small-margin {
        margin-left: 5px;
      }
    }
  }
  &.ant-btn-secondary {
    background-color: $secondary-color;
    color: #FFFFFF;
    border: none;
    &:hover {
      border: none;
      color: #ffffff;
      background-color: $secondary-color;
      opacity: 0.8;
    }
  }
  &.ant-btn-third {
    width: 100%;
    border: solid 1px $primary-color;
    color: $primary-color;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    &.active {
      background-color: #edeff7;
    }
    &.ant-btn-sm {
      height: 32px !important;
    }
  }
  &.tertiary-button {
    color: #ffffff;
    background-color: $orange-color;
    border: none;
    &:hover {
      color: #ffffff;
      background-color: $orange-color;
      opacity: 0.8;
    }
  }

  &:disabled {
    color: rgba(0,0,0,.25);
    border-color: #d9d9d9;
    background-color: #e0e0e0;
    text-shadow: none;
    box-shadow: none;
  }
}


// checkbox

.ant-checkbox-wrapper.small {
  font-size: 12px;

  .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    font-size: 12px;
    width: 12px;
    height: 12px;
    &:after {
      transform: rotate(45deg) scale(0.6) translate(-40%, -35%);
      top: 1px;
      left: 1px;
    }
  }
}

// menu


.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  width: calc(100%) !important;
}

.ant-menu-inline .ant-menu-item-selected::after {
  display: none;
}

// Tag

.ant-tag {
  border: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  padding: 4px 8px;
  min-width: 80px;
  text-align: center;

  > .current {
    font-size: 10px;
    line-height: 16px;
    padding: 4px;
    margin: 1px;
    font-weight: normal;
    color: white;
  }


  &.in-progress {
    background: #fdf0e0;
    color: #FF8400;
    border-color: #FF8400;
    .in-progress-icon {
      background : linear-gradient(to top, #FF8400 50%, #fdf0e0 50%);
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 50%;
      border: #FF8400 solid 1px;
      margin-right: 4px;
    }
  }

  &.pending {
    background: #ECECEC;
    color: #888888;

    .icon {
      background : #ECECEC;
      width: 9px;
      height: 9px;
      display: inline-block;
      border-radius: 50%;
      border: #888888 solid 1px;
      margin-right: 4px;
    }
  }

  &.unassigned {
    color: #888888;              
    background: #ECECEC;

    > .current {
      background: #888888;
    }
  }

  &.assigned {
    color: #0078D4;              
    background: #DEECF9;

    > .current {
      background: #0078D4;
    }
  }

  &.collected {
    color: #43C1C2;              
    background: #E1F5F5;

    > .current {
      background: #43C1C2;
    }
  }

  &.completed {
    color: #00C853;              
    background: #D6F6E3;

    > .current {
      background: #00C853;
    }
  }

  &.in-transit {
    color: #FF8400;
    background: #FFEBD6;

    > .current {
      background: #FF8400;;
    }
  }
}

.ant-picker {
  background: #f4f4f4 !important;
}

// tabs
.ant-tabs-top {
  background-color: #ffffff;
  // padding: 12px 24px;
}


.ant-image-preview-img {
  object-fit: contain;
}

.ant-image-preview-body {
  background-color: #fff;
}

.hidden {
  display: none !important;
}

//@at-root
.ant-select-multiple {
  .ant-select-selection-item-content {
    font-size: 12px;
    padding-right: 2px !important;
  }
}


//Circle follow width
.circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  padding: 3px;
  background: $primary-color;
  color: white;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}
.circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.circle span {
  display: inline-block;
  vertical-align: middle;
}
.ant-rate {
  .ant-rate-star-full {
    color: #FF8400;
  }
}
.switcher-btn {
  font-weight: 400;
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-control-input {
    min-height: 0;
  }
}
