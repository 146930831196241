@import "src/configs/styles/variables.scss";

.driver-feedback-page {
  display: flex;
  justify-content: center;

  .wrapper {
    width: 408px;
    min-height: 100vh;
    padding: 32px 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    background-color: $white-color;
    box-shadow: 0 2px 4px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 24%);

    .logo {
      width: 120px;
      height: auto;
      margin: 0 auto 24px;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
    }
    .rating-stars {
      margin-top: 8px;
      margin-bottom: 26px;
      width: 100%;
      text-align: center;
      .ant-rate {
        font-size: 42px;
        font-weight: bold;
      }
    }
    .sent-feedback-success {
        margin-top: 16px;
        text-align: center;
        .inform-note {
            margin-top: 24px;
            margin-bottom: 16px;
            color: $active-color;
            font-weight: 600;
        }
    }
  }
}
