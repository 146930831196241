@import "src/configs/styles/variables.scss";

.additional-vehicle-page {
  max-width: 792px;
  margin: auto;
  form {
    background-color: $white-color;
    .wrap-step-2-form {
      &:first-child {
        .wrap-card-title {
          padding-top: 12px;
          margin-bottom: 24px;
        }
      }
      .vehicle-status {
        padding-top: 30px;
      }
    }
  }
}