@import "/src/styles/variables/layouts.scss";
@import "/src/styles/mixins.scss";

@each $size, $length in $spacer-map {
  .gap-#{$size} {
    gap: $length !important;
  }
  .border-radius-#{$size} {
    border-radius: $length !important;
  }
}
