.auto-completed-input-hook {
  position: relative;
  ul {
    position: absolute;
    z-index: 99999999;
    width: 100%;
    list-style-type: none;
    padding: 0;
    li {
      padding: 10px;
      background-color: #f4f4f4;
      width: 100%;
      &:hover {
        background-color: #ccc;
      }
    }
  }
}