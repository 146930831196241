@import "src/styles/mixins.scss";

.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0 auto;
  width: 964px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 24%);
  border-radius: 4px;

  .image-logo {
    img {
      width: 137px;
      height: 40px;
      position: absolute;
      left: 16px;
      top: 16px;
    }
  }

  .image-logo {
    width: 576px;
    align-self: stretch;
    position: relative;
  }

  .form {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 32px 24px;

    .ant-radio-group {
      width: 100%;
      label {
        text-align: center;
        height: 40px;
        font-weight: 600;
        font-size: 14px;
        line-height: 34px;
        color: #888888;
        width: 50%;

        &.ant-radio-button-wrapper-checked {
          border: 1.5px solid #43c1c2;
          box-sizing: border-box;
          border-radius: 2px;
          color: #43c1c2;
        }
      }
    }

    .ant-form {
      width: 100%;
      height: 100%;

      .ant-form-item-extra {
        font-size: 10px;
        line-height: 16px;
        color: #888888;
      }

      .form-header {
        margin-bottom: 20px;

        .title {
          font-size: 24px;
          line-height: 40px;
          font-style: normal;
          font-weight: normal;
          margin-bottom: 4px;
        }

        p {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .terms-conditions {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        color: #212121;

        a {
          color: #5bc9ca;
          font-size: 14px;
          line-height: 24px;
          margin-left: 2px;
        }
      }

      .form-footer {
        button {
          height: 48px;
          background: #1f3998;
          border-radius: 2px;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          margin-top: 22px;
        }

        > div {
          text-align: center;
          margin-top: 20px;

          p,
          a {
            display: inline-block;
            font-size: 14px;
            line-height: 24px;
            margin: 0;
          }

          a {
            color: #7383bf;
            font-weight: 600;
            margin-left: 3px;
          }
        }
      }
    }
    .map {
      height: 248px;
    }
  }
}

@media screen and (min-width: 700px) {
  .register-page {
    width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .flexio-logo {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .register-page {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0px;
    background-color: white;
  }
  .image-logo {
    display: none;
  }

  .flexio-logo {
    width: 100%;
    height: 120px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 15px rgba(154, 154, 154, 0.2);
    z-index: 1;

    img {
      width: 170px;
      height: auto;
    }
  }

  .form {
    width: 100%;
    margin-bottom: 70px;
  }

  #under-footer {
    background-color: white;
  }
}
