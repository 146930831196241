.container {
  $offset: 12px;
  $green: #00C853;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .map {
    flex: 1;
    overflow: visible;
    z-index: 0;

    & > div {
      height: calc(100% + 16px);
      width: 100%;

      div[class=mapboxgl-ctrl-bottom-right] {
        display: none !important;
      }

      div[class=mapboxgl-ctrl-bottom-left] {
        display: none !important;
      }
    }
  }

  .bottomSheet {
    z-index: 1;
    $radius: 15px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    grid-template-columns: 1fr 1fr;
    padding: $offset 0;
    box-shadow: 0 0 5px #c7c7c7;
    column-gap: $offset;

    & > label {
      font-size: 16px;
      font-weight: 600;
      margin-left: $offset;
      margin-right: $offset;
    }

    & > p {
      margin: 4px $offset;
    }

    .hLine {
      background-color: #E0E0E0;
      height: 1px;
      width: 100%;
    }

    .driver {
      margin-left: $offset;
      margin-right: $offset;
      margin-top: $offset;
      display: grid;
      grid-template-columns: auto 1fr auto;
      column-gap: 10px;

      label {
        align-self: end;
        grid-area: 1/2;
      }

      p {
        align-self: start;
        margin-bottom: 0;
        grid-area: 2/2;
      }

      .avatar {
        grid-area: 1/1/span 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: rgba(31, 57, 152, 0.24);
        border-radius: 50%;
      }

      .plateNumber {
        background-color: #F4F4F4;
        grid-area: 1/3/span 2;
        align-self: center;
        border: 1px solid #212121;
        border-radius: 2px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .action {
      display: flex;
      margin-top: $offset;
      margin-right: $offset;
      margin-left: $offset;
      gap: $offset;

      .status {
        flex: 1;
        display: flex;
        align-items: center;
        border-radius: 24px;
        padding-left: $offset;
        padding-right: $offset;

        &.eta {
          gap: 2px;
          background-color: rgba(255, 171, 0, 0.16);

          & > span {
            margin-left: 6px;
            color: #888888;
          }
        }

        &.simple {
          justify-content: center;
          font-weight: bold;

          &.orange {
            background-color: rgba(255, 171, 0, 0.2);
            color: #ffab00;
          }

          &.red {
            background-color: rgba(255, 61, 23, 0.2);
            color: #FF3D17;
          }

          &.gray {
            background-color: #E0E0E0;
            color: #888888;
          }
        }
      }

      .btnCall {
        flex: 1;
        grid-area: 4/2;
        background-color: $green;
        border-color: $green;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #FFFFFF;
        gap: 10px;
      }
    }
  }

  .shipping {
    $iconSize: 40px;
    width: $iconSize;
    height: $iconSize;
    border-radius: $iconSize;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgba(67, 193, 194, 0.16);
    z-index: 0;

    &::before {
      $insetSize: $iconSize - 8px;
      content: '';
      background-color: #FFF;
      width: $insetSize;
      height: $insetSize;
      border-radius: $insetSize;
      position: absolute;
    }

    & > svg {
      z-index: 1;
    }

    &::after {
      $insetSize: $iconSize - 12px;
      position: absolute;
      content: '';
      background-color: #43C1C2;
      width: $insetSize;
      height: $insetSize;
      border-radius: $insetSize;
    }
  }

  .destination {

  }
}
