@import "src/styles/mixins.scss";

.admin-login-page {
  @include flex;
  @include align-items(center);
  padding-top: 104px;
  flex-direction: column;
  height: 100vh;

  #background {
    /* background-color: #1f3998; */
    background-color: #2491bc;
    position: fixed;
    top: 0;
    width: 100%;
    height: 440px;
    z-index: -1;
  }

  .logo {
    width: 220px;
    margin-bottom: 32px;
  }

  .form {
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 32px 24px;
    width: 408px;
    height: 430px;
    position: relative;

    .ant-form {
      width: 100%;
      height: 100%;
  
      .form-header {
        margin-bottom: 20px;

        .title {
          font-size: 24px;
          line-height: 40px;
          font-style: normal;
          font-weight: normal;
          margin-bottom: 4px;
        }

        p {
          font-size: 12px;
          line-height: 16px;
        }
      }
      button {
        margin-top: 22px;
      }

      .form-footer {
        margin-top: 20px;

        button {
          height: 48px;
          background: #1F3998;
          border-radius: 2px;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          margin-top: 0;
        }

        p {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
