@import "src/configs/styles/variables.scss";

.wrap-step-2-admin-form {
  .group-form {
    padding: 24px 24px 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }

  }
  .vehicle-types-check-list {
    padding: 0 24px;
    .ant-checkbox-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .ant-checkbox-group {
      @media only screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr;
        // column-gap: 20px;
      }
    }
  }
  .flexio-vehicle-types-check-list {
    .ant-checkbox-group {
      display: grid;
      grid-template-columns: repeat(3, 1fr)
    }
  }
}
