@import "src/styles/mixins.scss";

.create-form {
  @include flex;
  padding: 0 24px 12px 24px;
  @include flex-direction(column);
  
  .form-footer {
    margin-top: 20px;
    @include flex;
    @include justify-content(flex-end);

    button {
      border-radius: 2px;
      margin-left: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .ant-radio-group {
    width: 50%;
    label {
      text-align: center;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 34px;
      color: #888888;
      width: 50%;

      &.ant-radio-button-wrapper-checked {
        border: 1.5px solid #43C1C2;
        box-sizing: border-box;
        border-radius: 2px;
        color: #43C1C2;
      }
    }
  }

  .line-break {
    height: 1px;
    background: #f4f4f4;
    margin: 0 -24px;
  }
}
