@import "src/styles/mixins.scss";

.ClientForm {
  @include flex;
  padding: 0 24px 12px 24px;
  @include flex-direction(column);

  .form-footer {
    margin-top: 20px;
    @include flex;
    @include justify-content(flex-end);

    button {
      border-radius: 2px;
      margin-left: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .ant-radio-group {
    width: 100%;
    label {
      text-align: center;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 34px;
      color: #888888;
      width: 50%;

      &.ant-radio-button-wrapper-checked {
        border: 1.5px solid #43c1c2;
        box-sizing: border-box;
        border-radius: 2px;
        color: #43c1c2;
      }
    }
  }

  .react-tel-input {
    input.form-control {
      height: 40px;
    }
  }

  .line-break {
    height: 1px;
    background: #f4f4f4;
    margin: 0 -24px;
  }
}

.referral-link-container {
  margin-top: -8px;
  padding-bottom: 20px;
  p {
    font-size: 12px;
    margin-bottom: 4px;
  }
  .referral-link {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px 15px 15px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 25px;

    background-color: #f4f4f4;
    #span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
    }
  }
  .ant-btn {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
    color: white;
    background-color: #1f3998;
  }
}
