@import "/src/configs/styles/variables.scss";

.time-picker-hook {
  margin-bottom: 20px;
  .ant-picker {
    width: 100%;
  }
  .label-content {
    padding-bottom: 4px;
    label {
      font-weight: bold;
      font-size: 12px;
    }
    .require-status {
      font-weight: thin;
      font-size: 12px;
    }
  }
  .ant-picker-suffix {
    color: $primary-color;
  }
}
