@import "node_modules/@primer/css/utilities/index.scss";
@import "src/styles/utilities/flexbox.scss";

body {
  margin: 0;
  font-family: "Montserrat", "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.DraftEditor-root {
  border: solid 1px #dfdfdf;
  padding: 5px;
  min-height: 50px !important;
}

.card-button:hover {
  transition: all 0.5s ease;
  box-shadow: 2px 0 8px 1px #00000030;
}

// .scrollCustom::-webkit-scrollbar {
//   -webkit-appearance: none !important;
//   background-color: transparent !important;
//   width: 10px !important;
//   height: 10px !important;
// }

// .scrollCustom::-webkit-scrollbar-thumb {
//   background-clip: padding-box !important;
//   background-color: #e5e5e5 !important;
//   border-radius: 4px !important;
// }

// .scrollCustom::-webkit-scrollbar-thumb:hover {
//   background-color: rgba(134, 134, 134, 0.5) !important;
// }

// Hides hubspot chatbot
#hubspot-messages-iframe-container iframe {
  display: none !important;
}

#hubspot-messages-iframe-container iframe {
  left: auto !important;
  right: 0 !important;
}
#hubspot-messages-iframe-container.widget-align-left {
  left: auto !important;
  right: 0 !important;
}

.dot-container:hover {
  transform: scale(1.3) rotate(180deg);
}

.stats-card {
  transition: all 0.5s ease;
}

.stats-card:hover {
  transform: scale(1.01);
  box-shadow: 2px 2px 5px 0px #00000010;
}
