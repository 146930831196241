.react-tel-input {
  input[type='tel'] {
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: #e0e0e0;
      border-color: #f4f4f4;
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }
  }
}