.AdminBreadCrumb {
  margin-top: 4px;
  margin-bottom: 28px;
  display: inline-block;

  span.ant-breadcrumb-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  span.ant-breadcrumb-separator {
    color: #888888;
  }

  > span:last-child {
    span.ant-breadcrumb-link {
      color: #1f3998;

      a {
        color: #1f3998;
      }
    }
  }
}