@import "src/configs/styles/variables.scss";

.dashboard {
  .chart-list {
    .ant-col {
      padding: 12px;
      .chart-item {
        padding: 24px;
        background: $white-color;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        .title {
          margin-top: 0;
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
        }
        .total {
          margin: 0;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }

  .filter-order-component {
    margin: 0;
    margin-top: 36px;
  }

  .wrap-form-filter {
    align-items: center;
    .ant-checkbox-wrapper {
      // margin-top: 26px;
    }
    &.three-column {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.flex {
      display: flex;
    }
  }

  .ant-menu-horizontal {
    background: transparent;
    li:first-child {
      margin-left: -20px;
    }
  }
}
