.burger-container {
  position: absolute;
  top: 10px;
  z-index: 20;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;

  button {
    margin-right: 20px;
    background-color: white;
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) {
  .hide-if-desktop {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .hide-if-mobile {
    display: none;
  }
  .fixed-sider {
    position: fixed !important;
    max-width: 50% !important;
    z-index: 1000;
  }
}
