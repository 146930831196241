@import "src/configs/styles/variables.scss";

.driver-status {
  width: 70px;
  height: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  border-radius: 12px;
  font-size: 12px;
  &.pending {
    color: $secondary-text;
    background-color: #F4F4F4;
  }
  &.active, &.verified {
    color: $active-color;
    background-color: #D6F6E3;
  }
  &.deleting {
    color: $white-color;
    background-color: $tertiary-color;
  }
}
