.color-input-form {
  display: flex;
  justify-content: space-between;
  .wrap-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .ant-row {
    width: 265px;
  }

  .color-showed {
    width: 40px;
    height: 40px;
    background: #f4f4f4;
    border-color: #f4f4f4;
    box-shadow: none;
    padding: 4px;
    margin-right: 12px;
    .color {
      height: 30px;
      width: 30px;
      border-radius: 5px;
    }
  }
}
