@import "src/configs/styles/variables.scss";

.wrap-step-1-form {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  align-items: center;
  .link {
    color: $secondary-color;
  }

  .group-form {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  .agree-checkbox {
    margin-top: 30px;
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
