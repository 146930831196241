.close-btn-container {
    margin-top: 20px;
    position: relative;
    top: 80px;
    z-index: 999;
    display: flex;
    padding: 1em;
    justify-content: flex-end;
}

.close-btn-wrapper {
    cursor: pointer;
}

.close-icon {
    font-size: 1.5em;
    color: #3c56a6 !important;
    padding: .5em;
    transition: all .5s ease;
    border-radius: 50%;
    background-color: #00000020;
}

.close-btn-wrapper:hover .close-icon {
    background-color: #3c56a650;
    border-radius: 50%;
    color: #373A40 !important;
}