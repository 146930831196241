@import "src/configs/styles/variables.scss";

.driver-register-success-page {
  &.create-password-page {
    .main-content-page {
      display: flex;
      align-items: center;
      .wrap-content {
        @media only screen and (min-width: 767px) {
          width: 60%;
        }
      }
    }
  }
  position: relative;
  width: 100%;
  .header-title {
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    padding: 24px 0 0 24px;
  }
  .background-section {
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
  }
  .main-content-page {
    position: relative;
    display: flex;
    flex-direction: column;
    
    .wrap-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      padding-top: 80px;
      img {
        width: 220px;
      }
    }
    
    .wrap-content {
      @media only screen and (max-width: 767px) {
        width: 100%;
        min-height: 830px;
        border-radius: 20px 20px 0 0;
      }
      
      margin-top: 35px;
      padding: 24px;
      background-color: #ffffff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
      }
      svg {
        margin: 32px 0;
      }
      .orange-text {
        color: $orange-color;
        font-weight: 600;
        margin-bottom: 8px;
        font-size: 16px;
      }
      .green-text {
        color: $active-color;
        font-weight: 600;
        margin-bottom: 8px;
        font-size: 16px;
      }

      .description {
        width: 90%;
        // text-align: center;
        > div * {
          padding-bottom: 10px;
        }
      }
    }
  }
  .open-driver-btn {
    width: 100%;
    margin-top: 4px;
    text-align: center;
    background: #1f3998;
    color: #fff;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-weight: bold;
  }
}
.driver-register-success-page-web {
  position: relative;
  width: 100%;
  .header-title {
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    padding: 24px 0 0 24px;
  }
  .background-section {
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
  }
  .main-content-page {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    .wrap-body {
      background-color: #ffffff;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      border-radius: 20px 20px 0 0;
      height: 80vh;
      width: 60%;
      margin-top: 80px;
    }
    .wrap-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      padding-top: 80px;
      img {
        width: 220px;
      }
    }
    .wrap-content {
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      width: 60%;
      margin-top: 80px;
      padding: 24px;
      background-color: #ffffff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: center;
      .title {
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
      }
      svg {
        margin: 32px 0;
      }
      .orange-text {
        color: $orange-color;
        font-weight: 600;
        margin-bottom: 8px;
        font-size: 16px;
      }
      .green-text {
        color: $active-color;
        font-weight: 600;
        margin-bottom: 8px;
        font-size: 16px;
      }

      .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        // text-align: center;
        > div * {
          padding-bottom: 10px;
        }
      }
    }
  }
}
