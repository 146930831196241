.container {
  position: absolute;
  top:  0px;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: #bbbbbb80;
  backdrop-filter: blur(6px);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  @media screen and (max-width: 500px){
  }

  .subContainer {
    padding: 40px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 550px;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 500px){
      position: absolute;
      width: 300px;
      top: 50px;
    }
  
    .welcomeTitle {
      color: #1f3998;
      font-size: 34px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
      line-height: 1;
    }

    .iconsContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 20px;
      border: solid 1px #00a9d6;

      .iconSingleContainer {
        padding: 10px;
        width: 150px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .iconDescription {
          height: 30%;
          width: 95px;
          font-weight: bold;
          color: #00a9d6;
        }
      }
    }

    .subDescription {
      width: 100%;
      font-size: 20px;
      color: #666;
      text-align: center;
      line-height: 1.5;
      margin-bottom: 10px;
    }

    .button {
      background-color: #1f3998;
      color: white;
      padding: 20px 40px;
      font-size: 20px;
      line-height: 0;
    }
  }
}
