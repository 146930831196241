@import "src/styles/mixins.scss";

.order-header-component {
  @include flex;
  @include justify-content(space-between);
  @include align-items(center);
  
  &.filter {
    margin: 20px 0 0 0;
  }
  .buttons {
    .svg-icon {
      &.filter {
        z-index: 1;
        border-bottom: none;
      }
    }
  }
}
