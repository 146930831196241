@import "src/configs/styles/variables.scss";

.bold {
  font-weight: bold;
}

.textAlignCenter {
  text-align: center;
}
.wrap-content {
  width: 95% !important;
  margin-top: 0px !important;
}

.driver-register-page {
  position: relative;
  width: 100%;
  .background-section {
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    
  }
  .main-content-page {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .wrap-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      padding-top: 40px;
      img {
        width: 220px;
      }
    }
    .wrap-form-content {
      width: 792px;
      .title {
        font-size: 24px;
        margin-bottom: 24px;
        color: #ffffff;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .main-content-page {
      .wrap-form-content {
        width: 677px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .main-content-page {
      .wrap-form-content {
        margin: 20px;
        width: 100%;
        .wrap-steps {
          .title {
            margin-left: 10px;
          }
        }
        .wrap-form {
          margin: 10px;
        }
      }
    }
}


}
