.message {
  display: none;
  color: rgb(23, 213, 200);
  justify-content: center;
  align-items: center;
  opacity: 0;
  text-shadow:brown;
  height: 0px;
  animation: fadeIn 10s ease-in-out forwards;
  background-color: white;
  //padding: 30px 10px;
  font-size: 16px;
  z-index: 2;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    height: 0px;
  }
  10% {
    opacity: 1;
    height: 40px;
  }
  90% {
    opacity: 1;
    height: 40px;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

/* Apply the fade-in animation only when the screen is smaller than 700px */
@media screen and (max-width: 950px) {
  .message {
    display: flex;
    width: 100%;
    animation: fadeIn 10s ease-in-out forwards;
    animation-delay: 3s; /* Delay the animation start by 3 seconds */
  }
}
