@import "src/configs/styles/variables.scss";

.map-box-component {
  position: relative;
  .modal-marker-component {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.map-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .mapboxgl-map {
    border-radius: 4px;
  }
  .annotation-table {
    padding: 14px 12px;
    width: 50%;
    z-index: 9;
    position: absolute;
    bottom: 24px;
    right: 24px;
    .icon {
      width: 12px;
      height: 12px;
      border: solid 3px;
      &.delivery-pickup {
        border-color: $primary-color;
        border-radius: 50%;
      }
      &.delivery-dropoff {
        border-color: $primary-color;
        border-radius: 1px;
      }
      &.arrivedAt-pickup {
        border-color: $secondary-color;
        border-radius: 50%;
      }
      &.arrivedAt-dropoff {
        border-color: $secondary-color;
        border-radius: 1px;
      }
      &.completedAt-pickup {
        border-color: $active-color;
        border-radius: 50%;
      }
      &.completedAt-dropoff {
        border-color: $active-color;
        border-radius: 1px;
      }
    }
  }
}
