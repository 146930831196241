@import "/src/configs/styles/variables.scss";

.popup-map-box-component {
  .anticon-close {
    float: right;
    cursor: pointer;
  }
  .group-info {
    padding-right: 10px;
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 15px;
  }
  .view-more {
    color: $secondary-color;
    cursor: pointer;
  }
}

.order-popup {
  font-size: 12px;
  padding: 10px;
  min-width: 400px;
  .anticon-close {
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 5px;
    right: 5px;

  }
  .header {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    font-weight: bold;
    justify-content: space-between;
    // margin-right: 30px;
    .left {
      font-weight: 600;
    }
  }

  .content-top {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    .type {
      text-transform: capitalize;
      background: #f4f4f4;
      border-radius: 16px;
      padding: 4px 8px;
      margin-right: 8px;
    }
  }

  .content-main {
    table {
      margin-bottom: 8px;
      th {
        color: #888888;
        font-weight: 600;
      }
      th,
      td {
        text-align: left;
        padding-right: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;
        font-size: 12px;
      }
    }
  }

  .actions {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;

    // .ant-btn {
    //   border-color: #7732ef;
    //   color: #7732ef;
    //   span:first-child {
    //     margin: 0;
    //   }
    // }

    span.anticon {
      position: relative;
      top: 2px;
    }
  }
}
