.driver-broadcast-detail {
  .detail-content {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    width: 80%;
    margin: auto;
    padding: 24px;
    h2 {
      margin-left: -24px;
      margin-bottom: 16px;
    }
    label {
      display: block;
      &.sent-to {
        margin-bottom: 12px;
        font-size: 14px;
      }
    }
  }
}
