.stop_on_map {
  /* Ellipse 9 */
  position: relative;
  --size: 32px;
  --dropDownHeight: 8px;
  --innerPadding: 4px;
  --dropDownWidth: 8px;
  --shadowHeight: 6px;

  width: var(--size);
  height: calc(var(--size) + var(--dropDownHeight));
  cursor: pointer;
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}

.shape_in {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: var(--dropDownHeight);
  border-radius: 50%;
  border-width: var(--innerPadding);
  border-style: solid;
}

.shape_out {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: var(--dropDownHeight);
  border-radius: 10%;
}

.drop_down {
  position: absolute;
  width: var(--dropDownWidth);
  height: var(--dropDownHeight);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  left: calc(50% - var(--dropDownWidth) / 2);
  bottom: 1px;
}

.shadow {
  position: absolute;
  background: #888888;
  opacity: 0.5;
  filter: blur(4px);
  left: 12.5%;
  right: 12.5%;
  bottom: 0;
  height: var(--shadowHeight);
}

.child {
  position: absolute;
  left: var(--innerPadding);
  right: var(--innerPadding);
  top: var(--innerPadding);
  bottom: calc(var(--dropDownHeight) + var(--innerPadding));
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.completed {
  background: transparent;
}
