.customField {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  label{
    font-weight: bold;
    margin-bottom: 4px;
  }

  .checkItem {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;

    & > :last-child {
      margin-left: auto;
    }
  }
}
