.chart-container {
  display: flex;
  flex-direction: column;
  height: "auto";
  position: relative;
  // justify-content: space-between;
  .revert-button {
    color: #ff8400;
    &:disabled {
      color: #cecece;
    }
    &:hover {
      color: #ff8400;
      opacity: 0.8;
    }
  }
}

.button-actions {
  width: 100%;
  z-index: 1000;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .left-side-button {
    width: "100%";
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    button {
      margin-right: 16px;
    }
  }
  .right-side-button {
    width: "100%";
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    button {
      margin-right: 16px;
    }
  }
}
