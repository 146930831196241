.stopSearch {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 300px;
  z-index: 10;

  ul {
    background: #FFFFFF;
    border-radius: 4px;
    margin-top: 16px;
    list-style-type: none;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0 0 5px #c7c7c7;
  }

  li {
    cursor: pointer;
    padding: 10px;
    display: flex;
    gap: 10px;

    &:nth-child(even) {
      background-color: rgba(86, 102, 108, 0.1);
    }

    &:hover {
      background-color: rgba(86, 102, 108, 0.05);
    }

  }

  .result {
    display: flex;
    flex-direction: column;

    .indicator {
      $size: 18px;
      $color: rgb(131, 195, 255);
      min-width: $size;
      font-size: 12px;
      height: $size;
      border-radius: 50%;
      box-shadow: 0 0 3px #c7c7c7;
      border: 2px solid $color;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color;
    }
  }
}
