@import "src/styles/mixins.scss";

.medium-modal {
  .admin-content.form-content {
    width: 408px;
    margin: auto;
  }
}

.large-modal {
  .admin-content.form-content {
    width: 80%;
    margin: auto;
  }
}

.setting-header {
  @include flex;
  @include justify-content(space-between);
  margin-bottom: 20px;
  .ant-btn {
    margin-left: 16px;
  }
}

.admin-content {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.common-form {
  @include flex;
  padding: 24px 24px 12px 24px;
  @include flex-direction(column);
  
  .form-footer {
    margin-top: 20px;
    @include flex;
    @include justify-content(flex-end);

    button {
      border-radius: 2px;
      margin-left: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .line-break {
    height: 1px;
    background: #f4f4f4;
    margin: 0 -24px;
  }
}

.column-checkbox .ant-checkbox-group-item {
  display: flex !important;
  margin-right: 0 !important;
}

.split-two-form {
  padding-top: 17px !important;
  .form-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    column-gap: 24px;
  }
}

.group-check-box-with-description {
  border-style: solid;
  border-width: thin;
  margin-bottom: 20px;
  .check-box-with-description {
    margin-top: 10px;
    margin-left: 10px;
  }
  .two-description {
    margin-left: 34px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
    grid-column-gap: 24px;
    column-gap: 24px;
  }
  .three-description {
    margin-left: 34px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 10px;
  }
  .tag-description {
    margin-top: -2px;
    margin-left: 34px;
    margin-bottom: 10px;
  }
}

.group-check-box-service {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: "100%"
}

.select-package-types-title {
  margin-left: -24px;
  margin-bottom: 20px;
}

.select-vehicle-categories-title {
  margin-left: -24px;
  margin-bottom: 20px;
  margin-top: 12px;
}

.form-item-full-width {
  width: 100% !important;
}

.ratesSectionTitle {
  background-color: rgb(96, 120, 234);
  padding: 0.5em;
  margin: 0.5em;
  color: white;
}

.additionalBorder {
  border-top: solid 2px #DEDEDE;
  border-bottom: solid 2px #DEDEDE;
}

.version-content {
  padding: 1em;
}
.version-list-container {
  margin: 0 0 1em 0;
}
.version-list-container__item {
  margin: 0.3em 0;
  font-size: 1.2em;
  padding: 0 0 0 0.3em;
}