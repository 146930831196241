@import "src/configs/styles/variables.scss";

.job-detail {
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: $secondary-color;
  }
  .ant-steps-item-description {
    padding: 0;
  }

  .ant-tag {
    border: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    border-radius: 2px;
    padding: 4px 8px;
    min-width: 80px;
    text-align: center;

    > .current {
      font-size: 10px;
      line-height: 16px;
      padding: 4px;
      margin: 1px;
      font-weight: normal;
      color: white;
    }

    &.assigned {
      color: #0078D4;
      background: #DEECF9;

      > .current {
        background: #0078D4;
      }
    }

    &.collected {
      color: #43C1C2;
      background: #E1F5F5;

      > .current {
        background: #43C1C2;
      }
    }

    &.completed {
      color: #00C853;
      background: #D6F6E3;

      > .current {
        background: #00C853;
      }
    }

    &.in-transit {
      color: #FF8400;
      background: #FFEBD6;

      > .current {
        background: #FF8400;
      }
    }

    &.failed {
      color: #FF3D17;
      background: #FFE0DA;

      > .current {
        background: #FF3D17;
      }
    }
  }

  .wrapper {
    .left {
      background: white;
      padding: 24px;
    }
    .right-block {
      background: white;
      border-radius: 4px;


      &:not(:first-child) {
        margin-top: 24px;
      }

      .ant-divider-horizontal {
        margin: 13px 0;
      }

      .header {
        margin-bottom: 24px;

        h3 {
          display: inline-block;
          margin-right: 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
        }

        .count {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: #1F3998;
          border-radius: 50%;
          color: white;
          text-align: center;
        }
      }
    }
    .steps-job {
      .ant-steps-item {
        .ant-steps-item-container {
          .ant-steps-item-content {
            margin-bottom: 24px;
          }
          // .ant-steps-item-tail::after {
          //   background: #43C1C2;
          // }
          .ant-steps-item-content {
            min-height: 35px;

            .ant-steps-item-description {
              padding: 0;
              font-size: 12px;
            }
          }
          .ant-steps-icon {
            .ant-steps-icon-dot {
              border: 1px solid #43C1C2;
              box-sizing: border-box;
              background: #43C1C2;
            }
          }
        }

        &.ant-steps-item-active {
          .ant-steps-item-container {
            .ant-steps-item-tail::after {
              background: #E0E0E0;
            }
            .ant-steps-item-icon {
              width: 8px;
              height: 8px;

              .ant-steps-icon {
                .ant-steps-icon-dot {
                  left: 0;
                  box-sizing: border-box;
                }
              }
            }
          }
        }

        &.ant-steps-item-wait {
          .ant-steps-item-container {
            .ant-steps-item-icon {
              .ant-steps-icon {
                .ant-steps-icon-dot {
                  left: 0;
                  box-sizing: border-box;
                  border: 1px solid #E0E0E0;
                  background: #E0E0E0;
                }
              }
            }
          }
          .ant-steps-item-content {
            .ant-steps-item-title {
              span.ant-tag {
                color: #888888;              
                background: #ECECEC;
              }
            }
          }
        }
      }
    }

    .addresses {
      .address-info {
        padding: 24px 0 24px 24px;
        border-right: 1px solid #E0E0E0;
      }

      p {
        margin: 0px;
        font-size: 14px;
        line-height: 24px;
        /* or 171% */


        /* Primary Text */

        color: #212121;
      }
      label {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */


        /* Secondary Text */

        color: #888888;
      }
      .info {
        margin-bottom: 16px;

        .images {
          display: flex;
          margin-top: 4px;
          flex-direction: row;

          > .image {
            flex-basis: 92px;
            padding-right: 12px;
            height: 92px;
          }
        }
      }
    }
    .packages-block {
      padding: 24px;
      .content {
        .packages-by-type {
          .type {
            margin-bottom: 8px;
            b {
              font-size: 14px;
              line-height: 24px;
            }
          }
          .package {
            margin-bottom: 4px;

            
          }
        }
      }
    }

    .route-block {
      background: none;
      .item {
        padding: 24px;
        background: white;
        border-top: 1px solid #E0E0E0;

        &:first-child {
          border: none;
        }

        .header {
          margin-bottom: 8px;
        }

        .content {
          // text-align: center;

          > span {
            color : #888888;
            font-size: 14px;
            line-height: 24px;
          }
          .ant-image {
            margin-right: 10px;
          }
          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }

          .barcode-scanner-status, .cod-receive-status {
            display: inline-block;
            padding: 4px 12px;
            border-radius: 16px;
            text-transform: uppercase;
            font-weight: bolder;
            margin-right: 8px;
            color: #00c854;
            background: #d6f6e3;
            &.pending {
              color: #8e8e8e;
              background-color: #f4f4f4;
            }
          }
        }
      }

      .pod {
        min-height: 150px;
        .images {
          display: flex;
          margin-top: 4px;
          flex-direction: row;

          > .image {
            flex-basis: 92px;
            padding-right: 12px;
            height: 92px;
          }
        }
      }

      .signature {
        min-height: 200px;
        .content {
          > img {
            max-width: 213px;
          }
        }
      }

      .document-scanner {
        min-height: 150px;
      }

      .barcode-scanner {
      }
      .cod-receive {
        .content {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
