@import "src/styles/mixins.scss";

.CreateOrderPage {
  width: 100%;
  position: relative;
  .ant-btn.svg-icon {
    &.modify {
      align-items: flex-start;
    }
  }

  .fixed-map {
    position: fixed;
    left: 0;
    width: 100%;
    padding: 0 64px;
  }

  .create-order-relative-container {
    position: relative;
    z-index: 1;
    background: #fff;
  }

  .map-relative-container {
    position: relative;
    height: 400px;
    z-index: 0;
  }

  .switchs {
    @include flex;
    margin-bottom: 20px;

    .switch {
      margin-right: 29px;
      @include flex;

      p {
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        margin: 4px 0 0 8px;
      }

      button {
        line-height: 20px;

        &.ant-switch {
          border: 1px solid #e0e0e0;
          background-color: white;

          &:focus {
            box-shadow: none;
          }

          .ant-switch-handle {
            top: 1px;
            width: 17px;
            height: 17px;
          }

          &.ant-switch-checked {
            background: #e0f8ea;
            border: 1px solid #00c853;

            &:focus {
              box-shadow: none;
            }

            .ant-switch-handle::before {
              background-color: #00c853;
            }
          }
        }
      }
    }
  }

  .header-collapse {
    width: 100%;
    position: relative;

    .top {
      @include flex;

      p {
        padding-top: 1px;
        margin-left: 8px;
        margin-bottom: 4px;
        font-weight: normal;
      }
    }

    .bottom {
      font-size: 14px;
      line-height: 24px;
      color: #888888;
    }

    .icon {
      margin-right: 40px;
      position: absolute;
      right: 17px;
    }
  }
  .wrapper {
    background: white;
    padding: 0 0 24px 0;
    min-height: 300px;
    position: relative;

    h2.title {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding-left: 20px;
      color: #212121;
      border-left: #1f3998 solid 4px;
    }

    .sider {
      position: absolute;

      .ant-layout-sider {
        margin: 24px 48px 24px 24px;
      }

      .ant-menu-inline {
        border: none;
      }

      .ant-menu-item-selected,
      .ant-menu-item-active {
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }

      .ant-menu-inline .ant-menu-item::after {
        border-right: none;
      }
    }
    .contentForm {
      margin-left: 201px;
      padding: 0 15px;
      min-height: 400px;

      .block {
        padding-bottom: 24px;

        > .ant-collapse {
          > .ant-collapse-item {
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            border-radius: 4px;
            margin-bottom: 16px;
            position: relative;

            &.error-panel {
              border: 1px solid red;
            }

            > .ant-collapse-header {
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              padding: 24px !important;

              > .ant-collapse-extra {
                margin-right: 40px;
                position: absolute;
                top: 27px;
                right: 17px;
              }
            }
          }
        }
      }
      .ant-collapse-content-box {
        padding: 0;
      }
    }

    button.add-all {
      @include flex;
      margin-top: 32px;
      color: white;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      background: #43c1c2;
      border-radius: 2px;

      .react-icon {
        margin-left: 20px;
        padding-top: 1px;
      }
    }
  }

  .footer {
    background-color: white;
    bottom: -15px;
    left: 0;
    right: 0;
    width: 100%;
    position: sticky;
    z-index: 17;
    @include flex;
    @include justify-content(space-between);
    padding: 12px 140px;
    border-top: 1px solid #e0e0e0;

    > div {
      @include flex;

      button {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;

        &.create-order {
          margin-left: 16px;
        }

        &.save-as {
          @include flex;
          border: 1.5px solid #1f3998;
          color: #1f3998;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;

          .icon {
            padding-top: 2px;
            margin-left: 18px;
          }
        }
      }
    }
  }
}

.ant-select-dropdown {
  .ant-select-item {
    color: #212121;
    font-size: 14px;
    line-height: 32px;
  }
  .ant-select-item-group {
    font-weight: 600;
    text-transform: capitalize;
  }
  .ant-select-item-option-content {
    .hint {
      color: #888888;
    }
  }
}

.optimizing-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
}
