@import "/src/configs/styles/variables.scss";

.schedule-part-component {
  height: 48px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  background-color: #f9f9f9;
  .summary {
    color: $secondary-color;
  }
  svg {
    cursor: pointer;
  }
  .list-slots {
    width: 100%;
    height: 48px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: "-ms-autohiding-scrollbar";
    align-items: center;

    .slot-component {
      border: solid 1px #cecece;
      font-weight: bold;
      background-color: #ffffff;
      color: #cecece;
      cursor: pointer;
      margin: 0 4px;
      flex: 0 0 auto;
      &.pickup {
        border-radius: 12px;
      }
    }
    &.isDragging {
      border: dashed 1px #cecece;
    }
    &.optimize {
      cursor: no-drop !important;
      border: none;
    }
  }
  .left-side {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding-left: 58px;
    .unschedule-icon {
      margin: 0 $padding-default 0 5px;
    }
  }
  .right-side {
    margin-right: $padding-default;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.popover-summary-content {
  .info {
    padding-bottom: 12px;
    .info-item {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      padding: 10px 0;
      .item-value {
        font-weight: 600;
        width: 50px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .ant-btn {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    border-radius: 2px;
    border: none;
    box-shadow: none;
    padding: 8px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;

    .icon {
      height: 12px;
      margin-left: 8px;
      svg {
        height: 12px;
        width: 12px;
      }
    }

    &.btn-download {
      background: #fff;
      color: #7732ef;
      border: 1px solid #7732ef;
    }
  }
}
