.MainLayout {
  > .ant-layout-header {
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
    z-index: 2;
  }
  &.full-screen {
    .ant-layout {
      padding: 0;
    }
  }
  .main-content-layout {
    // max-width: calc(100% - 228px);
    // height: calc(100vh - 64px);
    overflow: auto;
    padding: 1em 2em;
  }

  .banner {
    margin-top: 2px;
    padding-top: 10px;
    width: 100%;
    min-height: 30px;
    max-height: 40px;
    background-color: #c75b54;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
  }
}
.badgeSize {
  min-width: 40px;
  margin-left: 20px;
}

.item {
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }
}

.custom-icon svg path {
  fill: #fff;
}
