@import "src/configs/styles/variables.scss";
@import "src/styles/mixins.scss";

.EditStopPage {
  width: 100%;
  position: relative;
  .ant-btn.svg-icon {
    &.modify {
      align-items: flex-start;
    }
  }

  .switchs {
    @include flex;
    margin-bottom: 20px;

    .switch {
      margin-right: 29px;
      @include flex;

      p {
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        margin: 4px 0 0 8px;
      }

      button {
        line-height: 20px;

        &.ant-switch {
          border: 1px solid #E0E0E0;
          background-color: white;

          &:focus {
            box-shadow: none;
          }

          .ant-switch-handle {
            top: 1px;
            width: 17px;
            height: 17px;
          }

          &.ant-switch-checked {
            background: #E0F8EA;
            border: 1px solid #00C853;

            &:focus {
              box-shadow: none;
            }

            .ant-switch-handle::before {
              background-color: #00C853;
            }
          }
        }
      }
    }
  }

  .header-collapse {
    width: 100%;
    position: relative;

    .top {
      @include flex;

      p {
        padding-top: 1px;
        margin-left: 8px;
        margin-bottom: 4px;
        font-weight: normal;
      }
    }

    .bottom {
      font-size: 14px;
      line-height: 24px;
      color: #888888;
    }

    .icon {
      margin-right: 40px;
      position: absolute;
      right: 17px;
    }
  }
  .wrapper {
    background: white;
    padding: 24px 0 24px 0;
    min-height: 300px;


    h2.title, h2.stop-type-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding-left: 20px;
      color: #212121;
      padding-left: 20px;
    }
    h2.title {
      border-left: #1F3998 solid 4px;
    }
    h2.stop-type-name {
      text-transform: uppercase;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .sider {
      position: absolute;

      .ant-layout-sider {
        margin: 24px 48px 24px 24px;
      }
  
      .ant-menu-inline {
        border: none;
      }
  
      .ant-menu-item-selected, .ant-menu-item-active {
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }

      .ant-menu-inline .ant-menu-item::after {
        border-right: none;
      }
    }
    .contentForm {
      min-height: 400px;
      padding-bottom: 6px;

        .block {
          padding-bottom: 24px;

          > .ant-collapse {
            > .ant-collapse-item {
              border: 1px solid #E0E0E0;
              box-sizing: border-box;
              border-radius: 4px;
              margin-bottom: 16px;
              position: relative;

              &.error-panel {
                border: 1px solid red;
              }
              
              > .ant-collapse-header {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                padding: 24px !important;

                > .ant-collapse-extra {
                  margin-right: 40px;
                  position: absolute;
                  top: 27px;
                  right: 17px;
                }
              }
            }
          }
        }
      .ant-collapse-content-box {
        padding: 0;
      }

      .package-list-section {
        border-top: 1px solid #E0E0E0;
        .PackageList {
          padding-left: 0;
        }
      }
    }

    button.add-all {
      @include flex;
      margin-top: 32px;
      color: white;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      background: #43C1C2;
      border-radius: 2px;

      .react-icon {
        margin-left: 20px;
        padding-top: 1px;
      }
    }
  }

  .footer {
    background-color: white;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: fixed;
    z-index: 17;
    @include flex;
    @include justify-content(flex-end);
    padding: 12px 24px;
    border-top: 1px solid #E0E0E0;

    > div {
      @include flex;
      
      button {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-left: 16px;
      }

      .delete-btn {
        color: $red-color;
        border-color: $red-color;
        svg {
          color: $red-color;
        }
      }
    }
  }
}
