@import "/src/configs/styles/variables.scss";

.timeline-driver-item {
  .driver-name {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: capitalize;
  }

  .driver-assign-action {
    height: 48px;
    display: flex;
    align-items: center;
    .list-slots {
      &.isDragging {
        border: dashed 1px #cecece;
        &.sort {
          border: none;
          cursor: no-drop;
        }
      }

      max-width: 120px;
      width: 120px;
      height: 48px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: auto;
      .slot-component {
        margin: 0 5px;
        flex: 0 0 auto;
        background-color: #fff;
        font-weight: bold;
        &.pickup {
          border-radius: 12px;
        }

      }
    }
  }
  svg {
    cursor: pointer;
    &.disabled {
      cursor: no-drop !important;
    }
  }
  .driver-time-group {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    .driver-time-action {
      // padding-right: $padding-default;
      margin-left: 12px;
      display: grid;
      grid-template-columns: auto auto auto;
      grid-column-gap: 10px;
      svg {
        cursor: pointer;
      }
    }
  }
  .chart-side-wrap {
    .time-hour {
      position: relative;
      height: 48px;
      .divide-line {
        width: 1px;
        position: absolute;
        left: 21px;
        height: 48px;
        border-left: solid 1px $border-color;
      }
    }
  }
  .driver-time {
    position: relative;
    width: 64px;
    height: 20px;
    text-align: center;
    border: solid 1px $primary-color;
    border-radius: 4px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    .number {
      position: relative;
    }
    .background-number {
      height: 18px;
      border-radius: 4px;
      background-color: #c6c9d9;
      position: absolute;
      top: 0;
    }
  }
}
