@import "src/styles/mixins.scss";

.PackageListDrop {
  padding: 24px 0 24px 23px;

  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #212121;
    margin-bottom: 12px;
  }

  .package-item {
    padding: 12px 0;
    border-bottom: 1px solid #E0E0E0;

    &:last-child {
      border-bottom: none;
    }

    b {
      text-transform: capitalize;
    }

    .top {
      margin-bottom: 8px;
    }

    .bottom {
      p {
        margin-bottom: 4px;
      }
    }
  }

  button.selected-pack {
    border: 1.5px solid #1F3998;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #1F3998;
    @include flex;
    width: 168px;
    margin-top: 24px;

    > .icon {
      margin-left: 19px;
      padding-top: 3px;
    }
  }
}
