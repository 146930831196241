.table-component {
  .wrap-search-input {
    margin-bottom: 12px;
  }
  .location-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  td {
    cursor: pointer;
  }
  .ant-spin-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
