@import "src/styles/mixins.scss";

.img-fluid {
  max-width: 100%;
}

.ant-carousel {
  height: 100% !important;
  .slick-slider {
    height: 100% !important;
    .slick-list {
      height: 100% !important;
      .slick-track {
        height: 100% !important;
      }
    }
  }
  .slick-dots-bottom {
    bottom: 40px !important;
  }
}

.slick-dots li {
  margin: 0 !important;
}

.slick-dots li button {
  width: 6px !important;
  height: 6px !important;
  border-radius: 100% !important;
}
.slick-dots li.slick-active button {
  width: 7px !important;
  height: 7px !important;
  border-radius: 100% !important;
  background: #fff !important;
}

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/images/flexio-2.0/blue-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: "Montserrat" !important;
  width: 100%;

  > div {
    position: relative;
    // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }

  .wrap-page {
    position: relative;
    // height: 436px;
    width: 940px;
  }

  .image-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .image-container {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin: -3em 0;
    background-image: url("/images/flexio-2.0/flexio-gradient.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
  }
  .blue-circle {
    position: absolute;
    background-color: #00a9d6;
    border-radius: 50%;
    width: 290px;
    height: 290px;
    bottom: 140px;
    max-width: 100%;
  }

  .image-logo {
    width: 137px;
    height: 40px;
    position: absolute;
    left: 16px;
    top: 16px;
  }

  .image-bg {
    width: 100%;
    max-width: 380px;
  }

  .image-logo-m {
    display: none;
  }

  .form {
    // background-color: #ffffff;
    padding: 26px 50px;
    max-width: 450px;
    // position: relative;
    flex: 1;

    .ant-form {
      width: 100%;
      height: 100%;

      input::placeholder {
        color: #1278f1;
        font-size: 14px;
      }

      .ant-input[type="text"] {
        border: solid 1px #1278f1;
        border-radius: 5px;
      }

      .input-password {
        .ant-form-item-control-input-content {
          border: solid 1px #1278f1;
          border-radius: 5px;
        }
      }

      .ant-input-password {
        border-radius: 5px;
      }

      .ant-input {
        border-radius: 5px;
      }

      .ant-form-item-label > label {
        color: #9197b3;
      }

      .checkbox-label {
        font-size: 12px;
      }

      .form-header {
        .title {
          font-size: 24px;
          line-height: 40px;
          font-style: normal;
          font-weight: normal;
          margin-bottom: 4px;
        }

        p {
          font-size: 14px;
          line-height: 16px;
          color: #9197b3;
          text-align: center;
          margin: 2.5em 0 2.5em 0;
        }
      }

      button {
        margin-top: 22px;
      }

      .form-footer {
        bottom: 32px;
        right: 24px;
        left: 24px;
        margin-top: 5em;
        // position: absolute;

        .sign-up {
          color: #01a9d7;
          font-weight: 800;
        }

        button {
          background-color: #1278f1;
          border-color: #1278f1;
          border-radius: 10px;
          margin: 2em auto 0 auto;
          display: block;
          font-size: 18px;
          padding: 0.4em 0;
          box-sizing: content-box;
        }

        > div {
          text-align: center;

          p,
          a {
            display: inline-block;
            font-size: 14px;
            line-height: 24px;
            margin: 10px 0 0 0;
            color: #9197b3;
          }

          p {
            font-size: 16px;
          }

          a {
            font-weight: 600;
            margin-left: 3px;
          }
        }
      }
    }

    .forgot-password {
      color: #01a9d7;
      font-weight: bold;
    }
  }

  .main-container {
    width: 100%;
    background-color: #fff;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;

    .website-text {
      position: relative;
      top: 75px;
      text-align: center;

      a {
        color: #fff;
      }
    }
  }

  .benefits-wrapper {
    height: 100%;
    display: flex !important;
    align-items: center;

    .benefits-container {
      max-width: 400px;
      height: auto;
      margin: 6em auto;
      padding: 0 2em;

      .benefits-title {
        color: #fff;
        font-size: 2em;
        font-weight: 800;
      }

      .benefits-list {
        margin: 4em 0;

        .benefits-list-item {
          display: flex;
          align-items: center;
          margin: 20px 0;
          color: #fff;
          font-size: 20px;
        }

        p {
          margin: 0 0 0 10px;
        }

        .benefits-icon {
          color: #78b254;
          font-size: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-container {
      display: none;
    }

    > div {
      position: relative;
      height: 100vh;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    }

    .wrap-page {
      position: relative;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .image-bg {
      display: none;
    }

    .image-logo-m {
      width: 100%;
      height: 20vh;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: #485faf;

      img {
        width: auto;
        height: 70px;
        margin-left: 0px;
      }
    }

    .main-container {
      justify-content: center;
    }

    .form {
      padding-top: 30px;
      background-color: #ffffff;
      border-radius: 3px;
      width: 100%;
      height: 80vh;

      // position: relative;

      .ant-form {
        width: 100%;
        height: 100%;

        .form-header {
          .title {
            font-size: 24px;
            line-height: 40px;
            font-style: normal;
            font-weight: normal;
            margin-bottom: 4px;
          }

          p {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
          }
        }

        button {
          margin-top: 22px;
        }

        .form-footer {
          bottom: 32px;
          right: 24px;
          left: 24px;
          // position: absolute;

          > div {
            text-align: center;
            margin-top: 16px;
            padding-bottom: 30px;

            p,
            a {
              display: inline-block;
              font-size: 14px;
              line-height: 24px;
              margin: 0;
            }

            a {
              font-weight: 600;
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    .image-logo-m {
      width: 100%;
      height: 10vh;

      img {
        width: auto;
        height: 40px;
      }
    }
  }
}
