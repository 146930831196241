@import "src/configs/styles/variables.scss";
.form-item-hook {
  margin-bottom: 20px;
  p.errors {
    padding-top: 5px;
  }
  .label-content {
    padding-bottom: 4px;
    display: flex;
    justify-content: space-between;
    label {
      font-weight: bold;
      font-size: 12px;
    }
    .require-status {
      font-weight: thin;
      font-size: 12px;
      color: $secondary-text;
    }
  }
  p.errors {
    color: $red-color;
  }
  .ant-select, .ant-picker {
    width: 100%;
  }
  .ant-select-multiple .ant-select-selection-item {
    height: 28px;
    align-items: center;
    border-radius: 14px;
    line-height: 24px;
    padding: 0 10px;
    .ant-select-selection-item-content {
      padding-right: 10px;
    }
    svg {
      color: $primary-color;
    }
  }
  .ant-picker-suffix {
    color: $primary-color;
  }
  .ant-radio-group, .ant-checkbox-group {
    margin-top: 8px;
  }
}
