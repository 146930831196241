.action-buttons {
  border-top: solid 1px #E0E0E0;
  padding-top: 12px;
  padding-right: 24px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .ant-btn-primary {
    margin-left: 24px;
  }
  .previous {
    margin-right: 24px;
  }
}