.radio-container {
  .ant-radio {
    position: absolute;
    opacity: 0;
  }
  .ant-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
  }

  .ant-radio .ant-radio-inner {
    position: absolute;
    opacity: 0;
  }

  .ant-radio + span {
    position: relative;
  }

  .ant-radio + span:before {
    content: "";
    background: #fff;
    border-radius: 50%;
    border: 1px solid #a6a6a6;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    cursor: pointer;
    text-align: center;
    transition: all 0.05s ease;
  }

  .package-radio {
    border: solid 1px #000;
    width: 190px;
    height: auto;
    padding: 0.4rem 0.8rem;
    margin: 1rem 0rem;
    border-radius: 10px;
    h3 {
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
    p {
      margin: 0rem 0;
    }
    .pool-price {
      margin: 0.5rem 0 0 0;
      font-weight: bold;
    }
  }
}

.radio-container.ant-radio-wrapper-checked {
  .ant-radio + span:before {
    background: #69caca;
    border: 2px solid #69caca;
    box-shadow: inset 0 0 0 2px #fff;
  }

  .package-radio {
    background: #69caca30;
    border: 1px solid #69caca;

    h3 {
      color: #035858;
    }
  }
}
