@import "src/styles/mixins.scss";

.header-content {
  @include flex;
  @include justify-content(space-between);
  background: #ffffff;
  align-items: center;
  .anticon {
    padding-top: 2px;
  }

  .hide-title {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .user-info {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    .ant-menu {
      //border: solid 1px black;
      min-width: 300px;
      .ant-menu-item {
        padding: 0 12px !important;
        &:first-child {
          padding-left: 24px !important;
        }
        &:last-child {
          padding-right: 24px !important;
        }
      }
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1111px) {
    .ant-menu {
      //border: solid 1px black;
      min-width: 600px;
      .ant-menu-item {
        padding: 0 12px !important;
        &:first-child {
          padding-left: 24px !important;
        }
        &:last-child {
          padding-right: 24px !important;
        }
      }
    }
  }

  @media only screen and (min-width: 1111px) {
    .ant-menu {
      min-width: 760px;
      .ant-menu-item {
        padding: 0 12px !important;
        &:first-child {
          padding-left: 24px !important;
        }
        &:last-child {
          padding-right: 24px !important;
        }
      }
    }
  }

  .ant-menu-item-selected::after,
  .ant-menu-item::after {
    border: none !important;
  }

  .ant-menu-item-selected {
    font-weight: 600;
    color: #212121 !important;

    a {
      font-weight: 600;
      color: #212121 !important;
    }
  }

  .ant-menu-item:hover {
    color: #212121 !important;
  }

  /*   > div {
    // padding: 12px 24px;
  } */

  .logo {
    object-fit: contain;
    a {
      @include flex;

      img {
        height: 30px;
        object-fit: contain;
      }
    }
  }

  .menu {
    ul {
      border: none;

      li {
        color: #888888;
        font-size: 14px;
        line-height: 24px;
        border: none;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .hide-responsive {
      display: none;
    }
  }
}

.create-help-container {
  display: flex;
  align-items: center;
}

.need-help {
  margin-left: 10px;
  div {
    gap: 5px;
    border: 1px solid #43c1c2;
    border-radius: 4px;

    button {
      padding: 0.5em 1em;
      display: flex;
      align-items: center;
      gap: 5px;
      span {
        display: flex;
        flex-direction: column;
        height: 15px;
        align-items: center;
        margin-top: -5px;
      }
    }
    button:hover {
      border: 1px solid #43c1c2;
    }
  }
}
.help-dropdown {
  width: 184px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0 5px !important;
} */
