.profile-info-banner__link {
  transition: all 0.5s ease;
  color: #fff;
  text-decoration: underline;
}
.profile-info-banner__link:hover {
  color: #fff;
  text-decoration: underline;
  font-weight: 500;
}
