@import "src/styles/mixins.scss";

.ClientForm {
  @include flex;
  padding: 0 24px 12px 24px;
  @include flex-direction(column);
  
  .form-footer {
    margin-top: 20px;
    @include flex;
    @include justify-content(flex-end);

    button {
      border-radius: 2px;
      margin-left: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }


  .react-tel-input { 
    input.form-control {
      height: 40px;
    }
  }

  .line-break {
    height: 1px;
    background: #f4f4f4;
    margin: 0 -24px;
  }
  .map {
    height: 248px;
  }
}
