.toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  -moz-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}
