@import "src/styles/mixins.scss";

.AdminClientHeader {
  @include flex;
  @include justify-content(space-between);

  .ant-btn {
    margin-left: 16px;
  }
}
