.import-locations-component {
  .header-table {
    text-align: left !important
  }
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    thead {
      border: solid 1px;
    }
    .header-table {
      text-align: left !important
    }
    tr {
      &.errors {
        background: red !important;
        color: white;
      }
    }
  }
  #client-table td, #client-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #client-table tr:nth-child(even){background-color: #f2f2f2;}

  #client-table tr:hover {background-color: #ddd;}

  #client-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #43C1C2;
    color: white;
  }
}
