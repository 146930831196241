.ant-row.ant-form-item {
  margin-bottom: 20px;

  .ant-form-item-label {
    padding-bottom: 4px;

    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      width: 100%;
    }
  }
  .ant-form-item-control-input, .ant-form-item-control-input-content{
    .ant-input-affix-wrapper {
      background-color: #f4f4f4 !important;
    }

    .ant-picker {
      width: 100%;
    }
  }
}
