@import "src/styles/mixins.scss";
@import "src/configs/styles/variables.scss";

.return-process-form {
  padding: 24px;
  h2.title, h2.form-name, h3.title-desc {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $text-color;
  }
  h2.title {
    padding-left: 20px;
    margin-left: -24px;
    border-left: $primary-color solid 4px;
    display: flex;
    justify-items: center;
    .switcher-btn {
      margin-left: 24px;
    }
  }
  h2.form-name {
    text-transform: uppercase;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  h3.title-desc {
    font-weight: normal
  }

  .form {
    .add-more-contact-btn {
      @include flex;
      padding: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: $secondary-color;
      span {
        display: inline-block;
        margin-right: 10px;
      }
      .react-icon {
        padding-top: 3px;
      }
    }
    .from_to {
      .ant-col-2 {
        margin-top: 9px;
      }
    }
    .phone_numbers {
      .ant-col-5.icon {
        padding: 9px;
      }
    }
    .map {
      height: 220px;
      margin-bottom: 20px;
    }
    .optional-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .right {
        font-weight: 400;
        color: $secondary-text;
      }
    }
    .ant-input-number {
      width: 100%;
    }
  }

  .form-footer {
    margin-left: -24px;
    margin-right: -24px;
    padding-right: 24px;
    padding-top: 12px;
    box-shadow: 0px -1px 0px #E0E0E0;
  }
}
