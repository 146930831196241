@import "src/configs/styles/variables.scss";

.service-step-component {
  padding: 24px;
  border: solid 1px $border-color;
  margin-bottom: 24px;

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  
  .pickup-date-item {
    margin: 24px 0 32px 0;
  }
  .wrap-plan-service {
    // height: 648px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    
  }
  .total-price {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    font-size: 20px;
    margin-right: 24px;
    .total {
      color: #43C1C2;
      font-weight: 400;
    }
  }

}
