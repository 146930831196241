.revenue-container {
    .description-text {
        font-size: 1.2em;
    }
    .big-text {
        font-size: 5em;
    }
}

.ant-card {
    border-radius: 10px !important;

    p {
        padding: 0;
        margin: 0;
    }

    .ant-card-body {
        padding: 1em !important;

        .simple-card-title {
            font-size: 1.5em;
        }

        .simple-card-amount {
            font-size: 2.5em;
            font-weight: bold;
            color: #45c9ed;
        }

        .simple-card-small-text {
            font-size: 0.9em;
        }
    }
}

.chart-revenue {
    .chart-header {
        h1 {
            font-size: 2em;
            margin: 0;
        }
    }
    .ant-select {
        .ant-select-selector {
            background: #fafbff !important;
            border-radius: 10px !important;
            border: 0 !important;
        }
    }
}


.title {
    font-size: 3em;
}

.list-filter-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 15em), 1fr));
    column-gap: 24px;
    grid-template-rows: 1fr auto;
}

@media only screen and (max-width: 1024px) {
    .revenue-container {
        .big-text {
            font-size: 3em;
        }
    }
}