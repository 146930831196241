.admin-filter {
  background-color: #fff;
  padding: 24px;
  margin-bottom: 24px;
  position: relative;
  top: -16px;
  border: solid 1px #E0E0E0;
  border-radius: 4px;
  .wrap-form-filter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 24px;
    align-items: center;
  }
  .wrap-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .collapse {
      color: #888888
    }
    .clear {
      margin-right: 10px;
    }
  }

}
