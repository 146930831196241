@import "src/configs/styles/variables.scss";
.driver-form-hook-component {
  .is-flexio-driver {
    margin: 8px 0 0 $padding-default;
  }
  .wrap-arrival-time {
    label {
      justify-content: space-between;
      padding-bottom: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $primary-text;
    }
  }
  .inline-content-select {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    margin-bottom: 20px;
    .ant-picker {
      width: 100%;
    }
    .time-picker-hook {
      margin-bottom: 0;
    }
    label {
      padding-bottom: 4px;
    }
    .text-to {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right {
      text-align: right;
    }
  }

  .form-control {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    border-top: solid 1px #cecece;
  }
  input[type='tel'] {
    padding-left: 50px;
  }
}