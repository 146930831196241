@import "src/configs/styles/variables.scss";

.service-type-component{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 4px;
  .wrap-box {
    padding: 16px;
    height: 100%;
    background-color: $background-color;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 252px;
  }
  padding: 16px;
  .service-title {
    margin-bottom: 14px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: $primary-color;
  }
  .list-name {
    margin-bottom: 16px;
    border-radius: 4px;
    padding: 10px;
    background-color: $primary-color;
    .name {
      margin-bottom: 5px;
      color: #ffffff;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .wrap-volumes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
  .wrap-description, .wrap-volumes {
    .description-item {
      margin-bottom: 8px;
      .description-title {
        margin-bottom: 4px;
        color: $secondary-text;
        font-weight: 600;
        font-size: 10px;
      }
      .description-content {
        font-size: 12px;
        color: $primary-text;
      }
    }
  }
  .view-detail-pickup {
    padding-left: 0;
    color: $primary-color;
    font-size: 12px;
    font-weight: 600;
  }
  .terms-conditions {
    a {
      color: #5bc9cA;
      font-size: 14px;
      line-height: 24px;
      margin-left: 2px;
    }
  }
  .wrap-return-pickup {
    .return-pickup {
      cursor: pointer;
      margin: 16px 0;
      text-align: center;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.return-modal-component {
  .ant-select-single {
    width: 100%;
  }
 
  .form-item-component {
    margin-bottom: 20px;
    textarea {
      height: 160px;
    }
  }
  .ant-btn {
    height: 40px;
  }
}
