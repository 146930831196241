.header-page-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .title-header {
    font-weight: 600;

  }
}