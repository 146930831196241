@import "src/styles/mixins.scss";
@import "src/configs/styles/variables.scss";

.ant-layout-header {
  line-height: 0 !important;
}

.header-user-info {
  @include flex;
  padding-left: 20px;
  justify-content: start;
  align-items: center;
  min-width: 160px;
  height: 64px;
  color: #004dbc;

  .avatar {
    position: relative;

    .notification {
      $indicatorSize: 12px;
      display: flex;
      position: absolute;
      right: 0;
      top: calc($indicatorSize / 2 * -1);
      cursor: pointer;
      width: $indicatorSize;
      height: $indicatorSize;
      margin: 8px 0;
      @include border-radius(50%);
      background-color: #e53935;
      color: #fff;
      font-weight: bold;
      font-size: 6px;
      z-index: 2;
      align-items: center;
      justify-content: center;
    }

    .first-chacracter {
      cursor: pointer;
      background-color: rgba(31, 57, 152, 0.24);
      @include border-radius(50%);
      width: 32px;
      height: 32px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #1f3998;
      text-align: center;
      overflow: hidden;

      p {
        padding-top: 8px;
      }
      &.clickable {
        cursor: pointer;
      }
    }
  }

  .info {
    @include flex;
    @include flex-direction(row);
    margin: 4px 0 4px 8px;
    min-width: 100px;
    align-items: center;

    b {
      font-size: 12px;
      line-height: 16px;
    }

    span {
      font-size: 10px;
      line-height: 16px;
      color: #888888;
    }
  }
}

.user-info-menu {
  width: 184px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.24);
  border-radius: 4px;

  .ant-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}

.company-info {
  margin: 0.5em 1em 1em 1.3em;
}

.company-email {
  font-size: 12px;
}

#logout-btn {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.notifications {
  max-height: 90vh;
  overflow-y: auto;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding: 16px;

  .item {
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
    }

    .unread {
      $size: 5px;
      width: $size;
      height: $size;
      min-width: $size;
      min-height: $size;
      margin-left: auto;
      border-radius: 50%;
      background-color: #ff3d17;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
