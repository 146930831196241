@import "src/styles/mixins.scss";

.SelectPack {
  span.ant-checkbox {
    span.ant-checkbox-inner {
      border: 2px solid #d9d9d9;
    }

    &.ant-checkbox-checked {
      span.ant-checkbox-inner {
        border: 1px solid #43C1C2;
      }
      .ant-checkbox-inner {
        background: #43C1C2;
      }
    }
  }

  .packItem {
    border-top: 1px solid #E0E0E0;
    padding: 12px 0;

    b {
      text-transform:capitalize;
    }

    .item {
      margin-top: 4px;

      &:first-child {
        margin-top: 8px;
      }

      .item-form {
        width: 40%;
        @include flex;

        .extra {
          min-width: 80px;
          padding-top: 35px;
          padding-left: 12px;

          span {
            font-size: 14px;
            line-height: 24px;
            color: #888888;
          }
        }
      }
    }

    &:first-child {
      border-top: none;
    }
  }
}
