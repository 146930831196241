@import "src/styles/mixins.scss";

.AddressForm {
  padding: 0 24px;
  border-bottom: 1px solid #e0e0e0;

  .from_to {
    .ant-col-2 {
      padding-top: 9px;
    }
  }

  .custom-label {
    margin-bottom: 4px;

    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      width: 100%;

      span {
        font-weight: normal;
      }
    }
  }

  .address {
    @include flex;
    @include justify-content(space-between);
    height: 260px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;

    .form {
      margin-right: 24px;
      flex: 1;
    }

    .map {
      flex: 2;

      img {
        width: 100%;
        max-height: 100%;
      }
    }
  }

  .pool-address {
    @include flex;
    @include justify-content(space-between);
    height: auto;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;

    .form {
      margin-right: 24px;
      flex: 1;
    }

    .map {
      flex: 2;

      img {
        width: 100%;
        max-height: 100%;
      }
    }
  }

  .info {
    padding-top: 20px;

    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      width: 100%;
    }

    .ant-upload {
      margin-bottom: 12px;
      margin-top: 4px;
      height: auto;
    }

    .ant-upload-list-picture-card-container {
      width: 64px;
      height: 64px;
      margin: 0 4.25px 6px 0;

      .ant-upload-list-item {
        padding: 0 !important;
      }

      .ant-upload-list-item-info::before {
        background: none;
      }
      .ant-upload-list-item-actions {
        top: 10px;
        left: 47px;

        button {
          div {
            margin-left: 6px;
          }
        }
      }
    }
    > .ant-row {
      .ant-col-8:not(:first-child) {
        padding-left: 17px !important;
      }
    }

    .phone_numbers {
      .ant-row {
        > .ant-col-8:first-child {
          padding-right: 11px;
        }
      }

      .icon {
        text-align: center;
        padding-top: 9px;
        cursor: pointer;
      }
    }

    .bottom-btn {
      button {
        @include flex;
        @include justify-content(flex-start);
        align-items: center;
        padding-left: 0;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;
          color: #43c1c2;
        }

        .react-icon {
          padding-top: 8px;
          margin-left: 10px;
        }
      }
    }
  }
  .additional-details {
    border-top: 1px solid #e0e0e0;
    .ant-collapse-header {
      padding: 20px 0 20px 0 !important;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
    .headerCollapse {
      @include flex;

      .icon {
        padding-top: 2px;
      }

      .text {
        margin-right: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #1f3998;
      }
    }

    .ant-col:not(:last-child) {
      padding-right: 24px;
    }
  }
}
