@import "src/configs/styles/variables.scss";

.api-key-page {
  .content {
    max-width: 800px;
    margin: 0 auto 20px;
    padding: 30px 30px 30px 0;
    background-color: $white-color;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);

    h1{
      text-align: center;
    }

    h3 {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      border-left: 4px solid $primary-color;
      padding-left: 20px;
    }
    .api-key-box {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      padding-right: 18px;
      border: 1px solid rgba(0, 0, 0, 0.24);
      border-radius: 25px;
      margin-left: 20px;
      background-color: #f4f4f4;
      // .ant-btn {
      //   padding-left: 12px;
      //   padding-right: 12px;
      //   border-radius: 20px;
      //   color: $white-color;
      //   background-color: $primary-color;
      // }
    }
  }
  .ant-btn {
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
    color: $white-color;
    background-color: $primary-color;
  }
}