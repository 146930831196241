@import "src/configs/styles/variables.scss";

.driver-new-page {
  padding: 24px;
  .ant-tabs {
    max-width: 792px;
    margin: auto;
  }
  .ant-tabs-nav-wrap {
    padding: 0 12px;
  }
  .additional-vehicles-tab {
    padding-left: 24px;
    padding-right: 24px;
    .add-additional-vehicle-btn {
      display: block;
      margin-top: 16px;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: $secondary-color;
    }
    .confirm-action {
      cursor: pointer;
    }
  }
}