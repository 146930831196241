@import "src/configs/styles/variables.scss";

.add-additional-vehicle-btn {
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 1.1em;
  line-height: 24px;
  color: $secondary-color;
}

.hide-if-mobile {
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.hide-if-desktop {
  @media screen and (min-width: 600px) {
    display: none;
  }
}
