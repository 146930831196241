@import "src/styles/mixins.scss";

.route-dispatch-detail {
  position: relative;
  .footer {
    background-color: white;
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 47;
    @include flex;
    @include justify-content(flex-end);
    padding: 12px 34px;
    border-top: 1px solid #E0E0E0;
    button {
      padding-left: 34px;
      padding-right: 34px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
