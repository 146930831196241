.create-password-component {
  width: 100%;
  padding:0;
  .ant-input-password {
    height: 40px;
  }
  .ant-btn-primary {
    width: 100%;
  }
}
.group-form {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
.ant-input-affix-wrapper{
  height:45px;
}