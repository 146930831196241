.MainAdminLayout {
  > .ant-layout-header {
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
    z-index: 2;
  }

  .main-content-layout {
    // max-width: calc(100% - 228px);
    // height: calc(100vh - 64px);
    overflow: auto;
  }
  .ant-layout-sider {
    height: calc(100vh - 64px);
  }
  &.full-screen {
    .ant-layout {
      .main-content-layout {
        padding: 0;
      }
    }
  }
  .ant-layout {
    .main-content-layout {
      padding: 24px;
    }
  }
}
