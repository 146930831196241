.select-route-component {
  
  .wrap-select-route {
    margin-top: 12px;
    margin-bottom: 8px;
    padding: 8px;
    background-color: #fff;
  }
  .wrap-checkbox-selected {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .vehicle-name {
      font-weight: bold;
    }
    .route-name {
      text-transform: capitalize;
    }
  }
  .sub-price {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  .price {
    color: #43C1C2;
  }
}
