.driver-app{
    div{
      gap: 10px;
      border-radius: 4px;
      height: 100%;
      display:flex;
      align-items:center;
      button{
        height: 100%;
        padding: .5em 1em;
        display:flex;
        align-items:center;
        gap: 10px;
      }
      button:hover{
        border: 1px solid #43C1C2;
      }
    }
}

.driver-apps {
    width: 184px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
}

