@import "/src/configs/styles/variables.scss";

.timeline-component {
  background-color: #ffffff;
  .group-drivers-chart {
    background-color: #ffffff;

    .driver-chart {
      background-color: #f9f9f9;
      .group-line-chart {
        background-color: #f9f9f9;
      }

      .info-side {
        svg {
          cursor: pointer;
        }
      }
      &:nth-child(2n + 1) {
        background-color: #ffffff;
        .chart-side {
          background-color: #ffffff;
        }
        .info-side {
          background-color: #ffffff;
        }
        .group-line-chart {
          background-color: #ffffff;
        }
      }
      &.header {
        background-color: #ffffff;
        .chart-side {
          background-color: #ffffff;
        }
        .info-side {
          background-color: #ffffff;
        }
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;
      .header-title {
        font-weight: bold;
        font-size: 14px;
        color: $primary-color;
        line-height: 48px;
      }
      .info-side {
        height: 48px;
        display: grid;
        align-items: center;
        grid-template-columns: 15px 15px 60px 150px;
        grid-column-gap: 19px;
        left: 0;
        padding-left: $padding-default;
        background: #f9f9f9;
      }
      .chart-side-wrap {
        display: flex;
      }

      .chart-side {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        height: 48px;
        align-items: center;
        background: #f9f9f9;
      }
    }
  }
}
