@import "src/configs/styles/variables.scss";

label {
  font-size: 12px;
}
.file-upload-hook {
  display: grid;
  grid-template-columns: 80px auto;
  column-gap: 8px;
}
.file-upload-container {
  display: flex;
  justify-content: center;
  position: relative;
  border: 1px dotted lightgray;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

}
.upload-file-btn {
  cursor: pointer;
  padding-top: 10px;
  font-size: 12px;
  border: none;
  background: none;
  color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;

}
.form-fields {
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  &:focus {
    outline: none;
  }

}
.file-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: #F4F4F4;
  span {
    font-size: 14px;
  }

}
.preview-list {
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }

}
.preview-container {
  height: 80px;
  width: 80px;
  border-radius: 6px;
  box-sizing: border-box;
  &:hover {
    opacity: 0.55;
    &.file-metadata {
      display: flex;
    }
  }
  & > div:first-of-type {
    height: 100%;
    position: relative;
  }
  @media only screen and (max-width: 750px) {
    width: 25%;
  }
  @media only screen and (max-width: 500px) {
    width: 50%;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
  }
}
.image-preview {
  border-radius: 6px;
  width: 80px;
  height: 80px;
}
.file-metadata {
  &.is-image-file {
    display: none;
  }
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);
  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
}
.wrap-image-preview {
  position: relative;
  width: 80px;
  height: 80px;
  .delete-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 999;
  }
}