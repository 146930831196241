@import "src/configs/styles/variables.scss";

.wrap-step-3-form {
  .group-form {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  .single-group {
    padding: 24px;
  }

  .vehicle-types-check-list {
    padding: 0 24px;
    .ant-checkbox-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }
  .commercial-general-checkbox {
    width: 26%;
  }
  .experience-deliveries-checkbox {
    width: 47%;
  }
  .rate-technology-checkbox {
    width: 90%;
  }
  .understand-language-checkbox  {
    width: 96%;
    @media only screen and (max-width: 767px) {
      width: 100%;
      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr;
    }
    @media only screen and (max-width: 375px) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .favorites-checkbox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 10px;
    @media only screen and (max-width: 767px) {
      width: 100%;
      display: grid;
      grid-template-columns: 0.5fr 0.5fr 150px;
    }
    @media only screen and (max-width: 375px) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }


  }

  
}
