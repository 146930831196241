.welcome-page{
    display:flex;
    width:100%;
    justify-content: center;
    margin: 0 auto;
    flex-direction: column;
    padding-top:3em;
    background-color: #FFFF;
}
.logo-img{
    display:flex;
    justify-content: center;
    img{
        max-width:200px;
    }
}

.login-button{
    width:100%;
    display:flex;
    justify-content: center;
}

.login-button > button{
    max-width: 200px;
    margin: 3em auto;
}

.welcome-page {
    h1, p{
        text-align:center;
    }
}