@import "src/configs/styles/variables.scss";

.form-item-component {
  .form-label {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $primary-text;

    .require {
      color: $red-color;
    }
    .right-label {
      color: $secondary-text;
      font-weight: 400;
    }
  }
}
