.route-detail {
  .ant-steps-item-description {
    padding: 0;
  }

  .wrapper {
    .left {
      background: white;
      padding: 24px;
    }

    .download-button {
      height: 22px;
      padding: 2px;
      background-color: "white";

      > svg {
        fill: grey;
      }
    }

    .detail {
      .status {
        span.ant-tag {
          padding: 8px;
          border: none;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;

          &.in-progress {
            background: #fdf0e0;
            color: #FF8400;

            .in-progress-icon {
              background : linear-gradient(to top, #FF8400 50%, #fdf0e0 50%);
              width: 8px;
              height: 8px;
              display: inline-block;
              border-radius: 50%;
              border: #FF8400 solid 1px;
              margin-right: 4px;
            }
          }
        }
      }

      .content {
        margin-top: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #212121;
        p {
          text-transform: capitalize;
        }
        label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #888888;
        }

        &:last-child {
          p {
            margin: 0;
          }
        }
      }
    }
    .right-block {
      background: white;
      padding: 24px;


      &:not(:first-child) {
        margin-top: 24px;
      }

      .ant-divider-horizontal {
        margin: 13px 0;
      }

      .header {
        margin-bottom: 24px;

        h3 {
          display: inline-block;
          margin-right: 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
        }

        .count {
          display: inline-block;
          min-width: 24px;
          min-height: 24px;
          // padding: 12px;
          background: #1F3998;
          border-radius: 50%;
          color: white;
          text-align: center;
        }
      }

      .job {
        cursor: pointer;

        .step-job {
          .ant-steps-item {
            .ant-steps-item-container {
              .ant-steps-item-tail::after {
                background: white;
                border: 1px dashed #43C1C2;
              }
              .ant-steps-item-content {
                min-height: 35px;

                b, p {
                  display: inline-block;
                  font-size: 14px;
                  line-height: 24px;
                  color: #212121;
                  margin: 0;
                }

                .ant-steps-item-description {
                  padding: 0;

                  b {
                    margin-right: 4px;
                  }
                }
              }
              .ant-steps-icon {
                .ant-steps-icon-dot {
                  background: #FFFFFF;

                  border: 1px solid #43C1C2;
                  box-sizing: border-box;
                }
              }
            }

            &.ant-steps-item-active {
              .ant-steps-item-container {
                .ant-steps-item-icon {
                  width: 8px;
                  height: 8px;

                  .ant-steps-icon {
                    .ant-steps-icon-dot {
                      left: 0;
                      background: #43C1C2;
                      /* Secondary */
    
                      border: 1px solid #43C1C2;
                      box-sizing: border-box;
                    }
                  }
                }
              }
            }
          }
        }
        .status-actions {
          svg {
            color: #E0E0E0;
          }

          .ant-tag {
            margin-right: 0 !important;
          }
        }
      }

      &.packages-block {
        .content {
          .packages-by-type {
            ul {
              padding-left: 30px;
            }
            .type {
              margin-bottom: 8px;
              b {
                font-size: 14px;
                line-height: 24px;
                text-transform: capitalize;
              }
            }
            .package {
              margin-bottom: 4px;
            }
          }
        }

        .packages-block-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ant-btn-third {
            width: auto;
          }
        }
      }
    }
  }
  .route-detail-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.change-job-status-modal {
  label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
  }
}
#packageBarcode {
  display: none;
}

.custom-heading-space{
  gap: 10px;
  .batch-right{
    margin-left: auto;
  }
}