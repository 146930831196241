.invoicing-list-page {
	.common-header {
		.buttons {
			.ant-btn {
			    margin-left: 8px;
			}
		}
	}
	.InvoicingTable {
		.ant-space {
			justify-content: space-between;
    		width: 100%;
		}
	}

	&.client {
		padding: 20px;
		.common-header {
    		h3 {
    			margin-left: -20px;
    			padding-left: 14px;
    		}
		}
	}
}