@import "src/configs/styles/variables.scss";

.settings-page {
  scroll-behavior: smooth;
  .content-main {
    background: transparent !important;
  }
  .setting-menu {
    padding: 14px;
    background-color: $white-color;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);
    button,
    a {
      display: block;
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 24px;
      font-weight: normal;
      &.active,
      &:hover {
        font-weight: 600;
        color: $secondary-color;
      }
    }

    a {
      padding: 8px 15px;
      color: #000000d9;

      &:hover {
        background-color: #00000005;
      }
    }
  }

  .setting-content {
    background-color: $white-color;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.24);

    .notification-setting-form {
      h3 {
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        border-left: 4px solid $primary-color;
        margin-left: -24px;
        padding-left: 20px;
      }
      .ant-row.ant-form-item .ant-form-item-label label,
      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }

      .ant-radio-group {
        label {
          font-weight: 400;
        }
      }

      .custom-fields {
        padding: 16px 0 8px 0;
        .title {
          font-size: 14px;
          line-height: 24px;
          i {
            color: #848282;
          }
        }
        .fields {
          display: flex;
          flex-wrap: wrap;
          .filed {
            background: #f4f4f4;
            border-radius: 16px;
            padding: 4px 12px;
            font-size: 14px;
            line-height: 24px;
            margin-right: 8px;
            margin-bottom: 8px;
            cursor: pointer;
          }
        }
      }

      .notification-messages {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        .ant-divider {
          height: auto;
          margin: 0px 24px;
        }
        .dropoff-noti-messages {
          display: flex;
          margin-top: 16px;
        }
        .pickup-noti-messages {
          margin-bottom: 16px;
        }
        .item {
          width: 33%;
          .ant-row {
            margin-bottom: 16px;
          }
          .ant-form-item-control-input-content {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            button,
            a {
              margin-right: 10px;
            }
          }
          .desc {
            font-size: 10px;
            line-height: 16px;
            margin-bottom: 16px;
            &.Preview {
              margin-bottom: 8px;
            }
          }
          textarea {
            background: #f4f4f4;
            border-radius: 4px;
            border: none;
            padding: 12px 14px;
            font-size: 14px;
            line-height: 20px;
          }
          .preview-content {
            background: #f4f4f4;
            border-radius: 12px;
            border: none;
            padding: 12px 14px;
            font-size: 14px;
            line-height: 20px;
            white-space: pre-line;
          }
        }
      }
      .copied {
        color: #ff3d17;
      }
    }
  }

  .ant-switch {
    width: 40px;
    height: 20px;
    min-width: 40px;

    background-color: #fff;
    border: 1px solid #e0e0e0;

    .ant-switch-handle {
      top: 1px;
      left: 1px;
      width: 16px;
      height: 16px;

      &:before {
        background: #e0e0e0;
        box-shadow: none;
      }

      &:after {
        content: "\00d7";
        position: absolute;
        top: -4px;
        left: 3px;
        width: 10px;
        height: 10px;
        color: #fff;
      }
    }

    &.ant-switch-checked {
      top: 1px;
      background-color: #e0f8ea;
      border: 1px solid #00c853;

      .ant-switch-handle {
        left: calc(100% - 18px - -1px);

        &:before {
          background: #00c853;
        }

        &:after {
          content: "";
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }

  .outlined-focus {
    animation-name: blink;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-iteration-count: 3;
    animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
    animation-fill-mode: forwards;
    animation-direction: alternate;
    border: solid 2px transparent;
  }

  @keyframes blink {
    from {
      border-color: #43c1c2;
    }
    to {
      border-color: transparent;
    }
  }
}
