.PackForm {
  #type {
    margin-top: 8px;

    label.ant-radio-wrapper {
      margin-right: 10px;
      font-size: 14px;
      line-height: 24px;

      .ant-radio-checked {
        .ant-radio-inner {
          border-color: #1f3998;
        }
        .ant-radio-inner::after {
          background-color: #1f3998;
        }
      }
    }
  }

  .custom-type {
    > .ant-row {
      > .ant-col {
        padding-right: 20px;
      }
    }
  }

  .normal-type {
    display: flex;
    margin: 30px 0;
    .box {
      max-width: 141px;
      margin-right: 32px;
    }
    .types {
      p, b {
        font-size: 14px;
        line-height: 24px;
      }
      p {
        display: block;
        padding-right: 16px;
      }
    }
  }

  .pack-info {
    > .ant-row {
      > .ant-col {
        padding-right: 17px;
      }
      > .ant-col:first-child {
        padding-right: 24px;
      }
    }

    .buttons {
      .ant-form-item {
        margin-top: 26px;

        .ant-form-item-control-input-content {
          button {
            width: 131px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            background: white;
            margin-right: 24px;
            height: 40px;
  
            &.cancel{
              color: #212121;
              border: 1.5px solid #212121;
              box-sizing: border-box;
              border-radius: 2px;
            }
            &.submit {
              color: #43C1C2;
              border: 1.5px solid #43C1C2;
              box-sizing: border-box;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}