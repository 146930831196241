.action-buttons {
  border-top: solid 1px #E0E0E0;
  padding-top: 12px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .ant-btn-primary {
    margin-left: 24px;
  }
  .previous {
    margin-right: 24px;
    @media only screen and (max-width: 767px) {
      display: none;
      margin-right: 12px;
    }

  }
  @media only screen and (max-width: 767px) {
    padding-right: 12px;
    .ant-btn-primary {
      margin-left: 12px;
    }

  }
}
