// ::-webkit-scrollbar {
//   // -webkit-appearance: none;
//   // width: 7px;
// }

// ::-webkit-scrollbar-thumb {
//   // border-radius: 4px;
//   // background-color: rgba(0, 0, 0, .3);
//   // box-shadow: 0 0 1px rgba(255, 255, 255, .5);
// }

/* Track */
// ::-webkit-scrollbar-track {
//   // box-shadow: inset 0 0 5px #bbbbbb;
//   // border-radius: 2px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   // background: #b9b9b9;
// }

.confirm-route-page {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 95%;

  // ::-webkit-scrollbar,
  // ::-webkit-scrollbar-thumb,
  // ::-webkit-scrollbar-track {
  //   all: unset;
  // }

  .button-actions {
    position: sticky;
    bottom: 0;
    width: 100% !important;
  }

  .confirm-route-title {
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: bold;
    background-color: #ffffff;
    padding-top: 16px;
    padding-bottom: 16px;

    &::before {
      width: 4px;
      height: 20px;
      background-color: #1f3998;
      content: "";
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
    }
  }
}
