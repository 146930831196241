@import 'src/styles/mixins.scss';

.header-content {
  @include flex;
  @include justify-content(space-between);
  height: 64px;
  background: #FFFFFF;

  > div {
    // padding: 12px 24px;
  }

  .left {
    width: 456px;
    position: relative;

    @include flex;
    @include justify-content(space-between);

    .logo {
      display: inline-block;
      a {
        @include flex;
  
        img {
          object-fit: contain;
          height: 40px;
        }
      }
    }

    .title {
      display: inline-block;
      padding-top: 8px;
      position: absolute;
      left: 200px;

      h1 {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
